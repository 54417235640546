import React, { useCallback, useState } from "react";
import "./Gallery.scss";
import Header from "./Header";
import Table from "./Table";
import AlbumDetails from "./Modals/AlbumDetailsModal/AlbumDetails";
import useGalleryStore from "../../store/galleryStore";
import AddAlbum from "./Modals/AddAlbumModal/AddAlbum";

const Gallery = () => {
  const { preView, editable } = useGalleryStore(state => state)
  const [query, setQuery] = useState('')

  return (
    <div className="wrapper">
      <div className="gallery-main-div">
        <Header setQuery={setQuery} />
        <div className="gallery-data-list">
          <Table query={query} />
        </div>
      </div>

      {preView?.show && <AlbumDetails />}
      {editable?.show && <AddAlbum />}
    </div>
  );
};

export default Gallery;
