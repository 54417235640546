import React, { useState } from "react";
import "./login.scss";
import { Link, useNavigate } from "react-router-dom";
import useAuthStore from "../../store/authStore";
import Swal from "sweetalert2";

const Login = () => {
  const navigate = useNavigate();
  const { loginUser } = useAuthStore((state) => state);

  const initialValues = {
    email: "",
    password: "",
  };
  const [values, setValues] = useState(initialValues);
  const { email, password } = values;

  const handleChange = (event) => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (email && password) {
      loginUser(values).then((res) => {
        if (res.result) navigate("/dashboard")
        setValues(initialValues);
      }).catch((err) => {
        if (err?.response?.data?.detail)
          Swal.fire({
            text: err?.response?.data?.detail,
            icon: 'error',
            confirmButtonText: 'Close'
          })
      });
    }
  };

  return (
    <div className="loginWrapper">
      <form onSubmit={handleSubmit}>
        <div className="inner-div">
          <img
            className="logo"
            src={process.env.PUBLIC_URL + "/assets/icons/faithBookLogo.svg"}
            alt="Faithbook"
          />
          <div className="inputWrapper">
            <label htmlFor="full-name" className="Login_label_fullName">
              Email
            </label>
            <input
              type="email"
              className="input-field"
              name="email"
              value={email}
              autoFocus={true}
              placeholder="username@example.com"
              onChange={handleChange}
              required
            />
          </div>

          <div className="inputWrapper">
            <label htmlFor="full-name" className="Login_label_password">
              Password
            </label>
            <input
              type="password"
              className="input-field"
              name="password"
              value={password}
              placeholder="********"
              onChange={handleChange}
              required
            />
          </div>

          {/* <div className="forgot pointer">
            <Link to={"/request-password"}>
              <h4>Forgot Password?</h4>
            </Link>
          </div> */}

          <button className="button pointer" type="submit">
            Login
          </button>
          <div className="request pointer">
            <Link to={"/request-password"}>
              <h3>Request for Password</h3>
            </Link>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Login;
