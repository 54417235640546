import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useOutsideClickDetect from "../../../../hooks/useOutsideClick";
import "./NewsUpdate.scss";
import useNewsStore from "../../../../store/newsStore";
import { BASE_URL, api } from "../../../../utils/api";
import useFetch from "../../../../hooks/useFetch";
import useAuthStore from "../../../../store/authStore";
import { constants } from "../../../../utils/constants";
import useUploadFileToS3 from "../../../../hooks/useUploadFileToS3";
import Swal from "sweetalert2";

const NewsUpdate = () => {
  const { editable, setEditableData, addEvent, refresh, setRefresh } = useNewsStore(store => store)
  const { user } = useAuthStore(store => store)
  const formRef = useRef(null);
  const listingData = useAuthStore(state=>state.listingData)

  const initialState = {
    title: "",
    body: "",
    author: "",
    authorName: "",
    audience: "",
    // new
    region: "",
    diocese: "",
    deanery: "",
    parish: "",
    // new
    audienceType: "",
    images: [],
    is_news: false,
    is_announcement: false,
  };

  const [values, setValues] = useState(initialState);
  const { title,
    body,
    author,
    audience,
    // new
    region,
    diocese,
    deanery,
    parish,
    // new
    audienceType,
    images,
    is_news,
    is_announcement,
  } = values;


  const filePath = `${constants.AWS_S3.news}`
  const [files, setFiles] = useState([])
  const { data } = useUploadFileToS3(files, filePath)

  const formFor = {
    "region": 1,
    "diocese": 2,
    "deanery": 3,
    "parish": 4,
  }

  const [color, setColor] = useState(null);
  const [labelColor, setLabelColor] = useState(null);
  useOutsideClickDetect(formRef, setEditableData);

  /* --------------------------Handlers-------------------------*/
  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    addEvent({ ...values, images: [...data], author: user.id, authorName: user.name, audience: values[audienceType] }).then((res) => {
      if (res) {
        Swal.fire({
          title: 'Success!',
          text: (is_news ? "News" : "Announcement") + " sent successfully",
          icon: 'success',
          confirmButtonText: 'OK'
        })
        setRefresh(!refresh)
        setEditableData({
          show: false,
          data: null,
        });
      }
    }).catch((err) => {
      Swal.fire({
        title: 'Error!',
        text: err.response.data.detail || Object.entries(err.response.data)[0],
        icon: 'error',
        confirmButtonText: 'OK'
      })
    });
  };

  /*--------Content----------*/
  const inputRef = useRef(null)

  const handleFileChange = (e) => {
    const fileRes = e.target.files[0];
    setValues({ ...values, images: [fileRes] })
    setFiles([fileRes])
  }

  // const listingData = useFetch(`${BASE_URL}${api.listing.listByRole}?role=${user.role}&value=${user[user.role.toLowerCase()]}`, true)


  const showSelection = {
    region: ['region', 'diocese', 'deanery', 'parish'],
    diocese: ['diocese', 'deanery', 'parish'],
    deanery: ['deanery', 'parish'],
    parish: ['parish']
  }
  
  const regionList = listingData && Object.keys(listingData)
  const dioceseList = region && listingData && listingData[region] && Object.keys(listingData[region]) || []
  const deaneryList = region && diocese && listingData && listingData[region] && listingData[region][diocese] && Object.keys(listingData[region][diocese]) || []
  const parishList = region && diocese && deanery && listingData && listingData[region] && listingData[region][diocese] && listingData[region][diocese][deanery] && Object.keys(listingData[region][diocese][deanery]) || []

  const handleColor = (button) => {
    setValues({ ...values, is_news: color == 1, is_announcement: color == 2 })
    setColor(button);
  };

  const handleLabelColor = (button) => {
    setValues({ ...values, audienceType: Object.keys(formFor)[+labelColor - 1] })
    setLabelColor(button);
  };

  return (
    <>
      {editable?.show && (
        <div className="newsModal-wrapper">
          <span><img src={process.env.PUBLIC_URL + "/assets/icons/charm_cross.svg"} alt="" /></span>
          <form
            className="news-update-form"
            ref={formRef}
            onSubmit={handleSubmit}
          >
            <div className="newsupdate-header">
              <span></span>
              <span className="btn-close"><img src={process.env.PUBLIC_URL + "/assets/icons/charm_cross.svg"} alt="" onClick={() => setEditableData(false)} /></span>
              <h4 className="newsupdate-heading">+New Update</h4>
              <span></span>
            </div>

            <div className="newsupdate-content">
              <h5 className="update-heading">Select type of update</h5>
              {/*------------------- TYPE OF UPDATE----------------- */}
              <div className="type-of-update">
                <label
                  onClick={() => handleColor(1)}
                  style={{
                    background: color === 1 ? "#194459" : "white",
                    color: color === 1 ? "white" : "#5b686e",
                  }}
                >
                  <input className="update-input" type="radio" />
                  <span>News</span>
                </label>

                <label
                  onClick={() => handleColor(2)}
                  style={{
                    background: color === 2 ? "#194459" : "white",
                    color: color === 2 ? "white" : "#5b686e",
                  }}
                >
                  <input className="update-input" type="radio" />
                  <span>Announcement</span>
                </label>
              </div>

              {/* -------------------TYPE OF AUDIENCE------------------ */}

              <h5 className="select-audience">Select type of update</h5>
              <div className="select-audience-type">
                <label
                  onClick={() => handleLabelColor(1)}
                  style={{
                    background: labelColor === 1 ? "#194459" : "white",
                    color: labelColor === 1 ? "white" : "#5b686e",
                  }}
                >
                  <input className="audience-input" type="radio" />
                  <span>Region</span>
                </label>

                <label
                  onClick={() => handleLabelColor(2)}
                  style={{
                    background: labelColor === 2 ? "#194459" : "white",
                    color: labelColor === 2 ? "white" : "#5b686e",
                  }}
                >
                  <input className="audience-input" type="radio" />
                  <span>Diocese</span>
                </label>
                <label
                  onClick={() => handleLabelColor(3)}
                  style={{
                    background: labelColor === 3 ? "#194459" : "white",
                    color: labelColor === 3 ? "white" : "#5b686e",
                  }}
                >
                  <input className="audience-input" type="radio" />
                  <span>Zone</span>
                </label>
                <label
                  onClick={() => handleLabelColor(4)}
                  style={{
                    background: labelColor === 4 ? "#194459" : "white",
                    color: labelColor === 4 ? "white" : "#5b686e",
                  }}
                >
                  <input className="audience-input" type="radio" />
                  <span>Parish</span>
                </label>
              </div>
              <div className="news-update-inputs">
               
                {/* NEW */}
                {showSelection.region.includes(values.audienceType) && <select name="region" onChange={handleChange} value={region} required>
                  <option value="">Select Region</option>
                  {regionList?.map((item, i) => {
                    if (item === null || item === "null") return null
                    return <option key={i} value={item}>{item}</option>
                  })}
                </select>}
                {showSelection.diocese.includes(values.audienceType) && <select name="diocese" onChange={handleChange} value={diocese} required>
                  <option value="">Select Diocese</option>
                  {dioceseList?.map((item, i) => {
                    if (item === null || item === "null") return null
                    return <option key={i} value={item}>{item}</option>
                  })}
                </select>}
                {showSelection.deanery.includes(values.audienceType) && <select name="deanery" onChange={handleChange} value={deanery} required>
                  <option value="">Select Deanery</option>
                  {deaneryList?.map((item, i) => {
                    if (item === null || item === "null") return null
                    return <option key={i} value={item}>{item}</option>
                  })}
                </select>}
                {showSelection.parish.includes(values.audienceType) && <select name="parish" onChange={handleChange} value={parish} required>
                  <option value="">Select Parish</option>
                  {parishList?.map((item, i) => {
                    if (item === null || item === "null") return null
                    return <option key={i} value={item}>{item}</option>
                  })}
                </select>}
                {/* NEW */}

                <input
                  type="text"
                  placeholder="Heading/Title"
                  name="title"
                  value={title}
                  onChange={handleChange}
                />
                <div className="body-wrapper">
                  <textarea
                    type="text"
                    placeholder="Body"
                    name="body"
                    value={body}
                    onChange={handleChange}
                  />
                  <span onClick={() => { inputRef && inputRef.current && inputRef.current.click() }}>
                    <input ref={inputRef} type="file" style={{ display: "none" }} onChange={handleFileChange} />
                    {values?.images?.map((img, i) => {
                      if (img.name) {
                        return <img key={i}
                          className=""
                          src={URL.createObjectURL(img)}
                          alt="Add"
                        />
                      }
                    })}
                  </span>
                </div>
              </div>
              <div
                className="news-button"
              >
                <button type="submit">Send</button>
              </div>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default NewsUpdate;
