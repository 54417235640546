import React from 'react'
import AdminRouter from './AdminRouter';
import OpenRouter from './OpenRouter';

const AppEntry = ({ auth }) => {

    switch (auth) {
        case true:
            return <AdminRouter />
        default:
            return <OpenRouter />
    }

}

export default AppEntry