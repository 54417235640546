import { useCallback, useEffect, useRef, useState } from "react";
import axios from "axios";
import Auth from "../utils/classes/classAUTH";

/**
 * @param {string} url - Endpoint of your api without params.
 * @param {object} params - QueryParams, like: offset (Start), limit (Range)
 * @param {void} setOffset - offset setter to udpate the start counter with adding the limit you pass
 * @param {string} key - key of json response in which the data array resides
 */

export default function useSearch(url, params, setPage, key) {
    let token = Auth.getToken() || "";
    const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
    };

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [data, setData] = useState([]);
    const [hasMore, setHasMore] = useState(false);

    const observer = useRef();
    const lastElement = useCallback(
        (node) => {
            if (loading) return;
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting) {
                    if (hasMore) setPage((prev) => prev + 1);
                }
            });
            if (node) observer.current.observe(node);
        },
        [loading, hasMore]
    );

    useEffect(() => {
        setData([])
    }, [(params.name || params.title || params.collegeName || params.albumName)])

    useEffect(() => {
        let cancel = () => { };
        if (params?.name == "") {
            return;
        }
        setLoading(true);
        setError(false);
        axios({
            method: "GET",
            url: url,
            params: params,
            headers: headers,
            cancelToken: new axios.CancelToken((c) => (cancel = c)),
        })
            .then((res) => {
                if (!res.data[key]) {
                    setLoading(false)
                    return setData([])
                }
                setData((prev) => {
                    return [...new Set([...prev, ...res.data[key]])];
                });
                setHasMore(res.data[key].length > 0);
                setLoading(false);
            })
            .catch((e) => {
                if (axios.isCancel(e)) return;
                setError(true);
            });

        return () => cancel();
    }, [(params.name || params.title || params.collegeName || params.albumName)]);

    return { loading, error, data, hasMore, lastElement };
}
