import React, { useRef, useState } from "react";
import "./Profile.scss";
import useOutsideClickDetect from "../../../../hooks/useOutsideClick";
import useAuthStore from "../../../../store/authStore";
import { useNavigate } from "react-router-dom";

const Profile = ({ showProfile, setShowProfile, setShowEditProfile }) => {

  const navigate = useNavigate()
  const { logoutUser, user } = useAuthStore((state) => state);

  const handleShowProfileModal = () => {
    setShowProfile(false);
  };

  const formRef = useRef(null);

  useOutsideClickDetect(formRef, setShowProfile);

  const [showMore, setShowMore] = useState(false);

  const handleShowMore = () => {
    setShowMore(!showMore);
  };

  return (
    <>
      {showProfile && (
        <div className="profile-wrapper">
          <span className="close" onClick={handleShowProfileModal}>
            <img
              className="charmCross-icon"
              src={process.env.PUBLIC_URL + "/assets/icons/charm_cross.svg"}
              alt=""
            />
          </span>
          <div ref={formRef} className="profile-maindiv">
            {/* -------------------profile HEADER ------------------- */}
            <div className="profile-content">
              {/* --------------HEADER------------- */}
              <div className="profile-header">
                <h4 className="profile-heading">
                  Profile
                  <span>

                    <img
                      className="edit-icon"
                      src={process.env.PUBLIC_URL + "/assets/icons/edit.svg"}
                      alt=""
                      onClick={() => setShowEditProfile(true)}
                    />
                  </span>
                </h4>
              </div>
              {/* -------profile-photo------ */}
              <div className="profile-pic ">
                <img
                  className="base-image"
                  src={user?.avatar || `${process.env.PUBLIC_URL}/assets/images/user.svg`}
                  alt=""
                />
                <span className="overlay-image2">
                  <img
                    src={process.env.PUBLIC_URL + "/assets/icons/camera.svg"}
                    alt=""
                  />
                </span>
              </div>
              <div className="profile-data">
                <h4 className="profile-name capitalize">{user.name}</h4>
                {user?.description ? <p>
                  {!showMore ? (
                    <>
                      {user?.description?.slice(0, 100) + "..."}
                      {user?.description?.length > 100 && <span onClick={handleShowMore} className="showMore">
                        show {showMore ? "less" : "more"}
                      </span>}
                    </>
                  ) : (
                    <>
                      {user?.description + " "}
                      {user?.description?.length > 100 && <span onClick={handleShowMore} className="showMore">
                        show {showMore ? "less" : "more"}
                      </span>}
                    </>
                  )}
                </p> : <p></p>}
              </div>
              <div className="test">
                <div className="profile-roles">
                  <h4 className="profile-role-heading">Role</h4>
                  <label className="profile-role-label capitalize" htmlFor="1">
                    {user.role}
                  </label>
                </div>
                {user.region && <h4 className="profile-region-name">Region Name</h4>}
                {user.diocese && <h4 className="profile-diocese-name">Diocese Name</h4>}
              </div>
              <label
                htmlFor="2"
                className="button-lable"
                onClick={handleShowProfileModal}
              >
                <img
                  src={process.env.PUBLIC_URL + "/assets/icons/logout.svg"}
                  alt=""
                />
                <button className="logout pointer" onClick={() => logoutUser(navigate)}>Logout</button>
              </label>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Profile;
