import moment from 'moment'
import React, { useState } from 'react'
import useInfiniteScroll from '../../hooks/useInfiniteScroll';
import { BASE_URL, api } from '../../utils/api';

const Birthdays = () => {

    const [page, setPage] = useState(1);
    // eslint-disable-next-line 
    const [size, setSize] = useState(10);

    const { data, lastElement, loading } = useInfiniteScroll(
        BASE_URL + api.dashboard.birthdays,
        {
            page: page,
            size: size,
            is_deleted: false,
            role: "youth",
            web: true
        },
        setPage,
        "results"
    );

    return (
        <div className="birthday-listing-wrapper">
            <div className="heading">
                <h4>Birthday</h4>
            </div>
            <div className="data-list">
                <table>
                    <tbody>
                        {data && data.map((item, index) => {
                            if (data.length === index + 1) {
                                return <tr key={index} ref={lastElement}>
                                    <td className="name-cell">
                                        <span className="ellipse">
                                            <img src={item?.avatar ? item?.avatar : `${process.env.PUBLIC_URL}/assets/images/user.svg`} alt="" />
                                        </span>
                                        <p>{item.name}</p>
                                    </td>
                                    <td>{moment(item.dob).format("DD/MM/YYYY")}</td>
                                </tr>
                            }
                            return <tr key={index} >
                                <td className="name-cell">
                                    <span className="ellipse">
                                        <img src={item?.avatar ? item?.avatar : `${process.env.PUBLIC_URL}/assets/images/user.svg`} alt="" />
                                    </span>
                                    <p>{item.name}</p>
                                </td>
                                <td>{moment(item.dob).format("DD/MM/YYYY")}</td>
                            </tr>

                        })}
                    </tbody>
                </table>
                {loading && <div className="loading"><span>Loading</span></div>}
            </div>
        </div >
    )
}

export default Birthdays