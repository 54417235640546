import React from "react";
import "./Education.scss";
import useYouthSectionStore from "../../../store/youthSectionStore";
import moment from "moment";

const Education = () => {
  const { setPreView, preView } = useYouthSectionStore(state => state)
  const { education } = preView?.data
  return (
    <div className="education-wrapper">
      {education?.doctorate && <div className="post-graduation">
        <h3>Doctorate</h3>
        <div className="education-names">
          {/* --------University-------- */}
          <div>
            <div className="university-heading">
              <img
                className="university"
                src={`${process.env.PUBLIC_URL}/assets/icons/university.svg`}
                alt="back"
              />
              <h6>university Name</h6>
            </div>
            <input type="text" placeholder="university Name" value={education.doctorate.univerityName} readOnly />
          </div>

          {/* ------------college--------- */}
          <div>
            <div className="college-heading">
              <img
                className="college"
                src={`${process.env.PUBLIC_URL}/assets/icons/university.svg`}
                alt="back"
              />
              <h6>College's Name</h6>
            </div>
            <input type="text" placeholder="College Name" value={education.doctorate.collegeName} readOnly />
          </div>
        </div>

        <div className="degree">
          {/* --------degree-------- */}
          <div>
            <div className="degree-heading">
              <img
                className="degree"
                src={`${process.env.PUBLIC_URL}/assets/icons/degree.svg`}
                alt="back"
              />
              <h6>Degree</h6>
            </div>
            <input type="text" placeholder="No.of degrees" value={education.doctorate.degree} readOnly />
          </div>

          {/* ------------location--------- */}
          <div>
            <div className="location-heading">
              <img
                className="location"
                src={`${process.env.PUBLIC_URL}/assets/icons/location.svg`}
                alt="back"
              />
              <h6>Location</h6>
            </div>
            <input type="text" placeholder="Location" value={education.doctorate.location} readOnly />
          </div>
        </div>

        <div className="dates">
          <div className="start-date">
            <h6>Start Dates</h6>
            <input type="text" placeholder="Start Date" value={moment(education.doctorate.startDate).format("DD/MM/YYYY")} readOnly />
          </div>

         {education?.doctorate?.endDate && <div className="end-date">
            <h6>End Dates</h6>
            <input type="text" placeholder="End Date" value={moment(education.doctorate.endDate).format("DD/MM/YYYY")} readOnly />
          </div>}
        </div>


      </div>}

      {education?.postGraduation && <div className="post-graduation">
        <h3>Post Graduation</h3>
        <div className="education-names">
          {/* --------University-------- */}
          <div>
            <div className="university-heading">
              <img
                className="university"
                src={`${process.env.PUBLIC_URL}/assets/icons/university.svg`}
                alt="back"
              />
              <h6>university Name</h6>
            </div>
            <input type="text" placeholder="university Name" value={education.postGraduation.univerityName} readOnly />
          </div>

          {/* ------------college--------- */}
          <div>
            <div className="college-heading">
              <img
                className="college"
                src={`${process.env.PUBLIC_URL}/assets/icons/university.svg`}
                alt="back"
              />
              <h6>College's Name</h6>
            </div>
            <input type="text" placeholder="College Name" value={education.postGraduation.collegeName} readOnly />
          </div>
        </div>

        <div className="degree">
          {/* --------degree-------- */}
          <div>
            <div className="degree-heading">
              <img
                className="degree"
                src={`${process.env.PUBLIC_URL}/assets/icons/degree.svg`}
                alt="back"
              />
              <h6>Degree</h6>
            </div>
            <input type="text" placeholder="No.of degrees" value={education.postGraduation.degree} readOnly />
          </div>

          {/* ------------location--------- */}
          <div>
            <div className="location-heading">
              <img
                className="location"
                src={`${process.env.PUBLIC_URL}/assets/icons/location.svg`}
                alt="back"
              />
              <h6>No. of locations</h6>
            </div>
            <input type="text" placeholder="No. of locations" value={education.postGraduation.location} readOnly />
          </div>
        </div>

        <div className="dates">
          <div className="start-date">
            <h6>Start Dates</h6>
            <input type="text" placeholder="Start Date" value={moment(education.postGraduation.startDate).format("DD/MM/YYYY")} readOnly />
          </div>

          {education?.postGraduation?.endDate && <div className="end-date">
            <h6>End Dates</h6>
            <input type="text" placeholder="End Date" value={moment(education.postGraduation.endDate).format("DD/MM/YYYY")} readOnly />
          </div>}
        </div>


      </div>}
      
      {education?.graduation && <div className="graduation">
        <h3>Graduation</h3>
        <div className="education-names">
          {/* --------University-------- */}
          <div>
            <div className="university-heading">
              <img
                className="university"
                src={`${process.env.PUBLIC_URL}/assets/icons/university.svg`}
                alt="back"
              />
              <h6>university Name</h6>
            </div>
            <input type="text" placeholder="university Name" value={education.graduation.univerityName} readOnly />
          </div>

          {/* ------------college--------- */}
          <div>
            <div className="college-heading">
              <img
                className="college"
                src={`${process.env.PUBLIC_URL}/assets/icons/university.svg`}
                alt="back"
              />
              <h6>College's Name</h6>
            </div>
            <input type="text" placeholder="College Name" value={education.graduation.collegeName} readOnly />
          </div>
        </div>

        <div className="degree">
          {/* --------degree-------- */}
          <div>
            <div className="degree-heading">
              <img
                className="degree"
                src={`${process.env.PUBLIC_URL}/assets/icons/degree.svg`}
                alt="back"
              />
              <h6>Degree</h6>
            </div>
            <input type="text" placeholder="No.of degrees" value={education.graduation.degree} readOnly />
          </div>

          {/* ------------location--------- */}
          <div>
            <div className="location-heading">
              <img
                className="location"
                src={`${process.env.PUBLIC_URL}/assets/icons/location.svg`}
                alt="back"
              />
              <h6>No. of locations</h6>
            </div>
            <input type="text" placeholder="No. of locations" value={education.graduation.location} readOnly />
          </div>
        </div>

        <div className="dates">
          <div className="start-date">
            <h6>Start Dates</h6>
            <input type="text" placeholder="Start Date" value={moment(education.graduation.startDate).format("DD/MM/YYYY")} readOnly />
          </div>

         {education?.graduation?.endDate && <div className="end-date">
            <h6>End Dates</h6>
            <input type="text" placeholder="End Date" value={moment(education.graduation.endDate).format("DD/MM/YYYY")} readOnly />
          </div>}
        </div>


      </div>}




    </div>
  );
};

export default Education;
