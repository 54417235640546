import React, { useRef } from "react";
import "./PreviewCollege.scss";
import useOutsideClickDetect from "../../../../hooks/useOutsideClick";
import useCampusStore from "../../../../store/campusStore";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { constants } from "../../../../utils/constants";
import useAuthStore from "../../../../store/authStore";

const PreviewCollege = () => {
  const navigate = useNavigate()
  const formRef = useRef(null);
  const { user } = useAuthStore(state => state)
  const { preView, setPreView, setEditableData, deleteEvent, refresh, setRefresh } = useCampusStore(state => state)

  useOutsideClickDetect(formRef, setPreView);

  const {
    id,
    collegeName,
    universiyName,
    address,
    location,
    email,
    website,
    description, images
  } = preView?.data

  const handleEdit = (ID) => {
    setEditableData(preView)
    navigate("?id=" + ID)
  }

  const handleDelete = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#FF3E4D",
      confirmButtonText: 'Delete'
    }).then((result) => {
      if (result.isConfirmed) {
        deleteEvent({ id: id }).then((res) => {
          if (res) {
            setPreView({ show: false, data: null })
            setRefresh(!refresh)
          }
        }).catch((err) => {
          Swal.fire({
            title: 'Error!',
            text: err.response.data.detail,
            icon: 'error',
            confirmButtonText: 'OK'
          })
        })
      }
    })
  }

  return (
    <div className="preview-details-wrapper">
      <div ref={formRef} className="preview-details-maindiv">
        {/* HEADING */}

        <div className="preview-details-header">
          <span></span>
          <span className="btn-close"><img src={process.env.PUBLIC_URL + "/assets/icons/charm_cross.svg"} alt="" onClick={() => setPreView(false)} /></span>
          <h4 className="preview-details-heading">College Details</h4>
          <div className="preview-details-header-icons">
            {user.role === constants.role.ADMIN && <>
              <img
                className=""
                src={process.env.PUBLIC_URL + "/assets/icons/edit.svg"}
                alt=""
                onClick={() => handleEdit(id)}
              />
              <img
                className=""
                src={process.env.PUBLIC_URL + "/assets/icons/delete.svg"}
                alt=""
                onClick={() => handleDelete(id)}
              />
            </>}
          </div>
        </div>

        {/* CONTENT */}
        <div className="preview-details-content">
          <h4 className="preview-details-title"><span className="heading">College Name:</span><span>{collegeName}</span></h4>
          <h4 className="preview-details-title"><span className="heading">University Name:</span><span>{universiyName}</span></h4>
          <span className="preview-details-title"><span className="heading">Address:</span><span>{address}</span></span>
          <h4 className="preview-details-title"><span className="heading">Location:</span><span>{location}</span></h4>
          <h4 className="preview-details-title"><span className="heading">Email:</span><span>{email}</span></h4>
          <h4 className="preview-details-title"><span className="heading">Website:</span><span>{website}</span></h4>
          <p className="preview-details-title"><span className="heading">Description:</span><span>{description}</span></p>
          <div className="preview-preview-details-img">
            {images?.map((img, i) => {
              return <label className="card-body" htmlFor={i} key={i}>
                <img
                  className=""
                  src={img}
                  alt="Add"
                />
              </label>
            })}
            {/* <label className="card-body" htmlFor="1"></label>
            <label className="card-body" htmlFor="2"></label>
            <label className="card-body" htmlFor="3"></label>
            <label className="card-body" htmlFor="4"></label> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreviewCollege;
