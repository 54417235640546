import React from "react";
import { DebounceInput } from "react-debounce-input";
import useAuthStore from "../../store/authStore";
import useGalleryStore from "../../store/galleryStore";
import { useNavigate } from "react-router-dom";

const Header = ({ setQuery }) => {
  const navigate = useNavigate()
  const { setEditableData } = useGalleryStore(state => state)
  const handleSearch = (e) => setQuery(e.target.value)
  const handleShowAdd = () => { navigate("/gallery"); setEditableData({ show: true, data: null }); }
  return (
    <div className="head-wrapper">
      <div className="gallery-roles">
        <h3>Gallery</h3>
      </div>
      <div className="gallery-search-wrapper">
        <div className="rectangle-group">
          <img
            className="search-icon"
            src={process.env.PUBLIC_URL + "/assets/icons/search.svg"}
            alt=""
          />
          {/* <input type="text" className="text-area" placeholder="Search" /> */}
          <DebounceInput
            minLength={2}
            debounceTimeout={500}
            className="text-area"
            placeholder={"Search"}
            onChange={handleSearch}
          />
        </div>
        <button className="add-btn pointer" onClick={handleShowAdd}>
          +Add new
        </button>
      </div>
    </div>
  );
};

export default Header;
