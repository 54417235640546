import React, { useState } from "react";
import "./RequestPassword.scss";
import API from "../../utils/classes/classAPI"
import { api } from "../../utils/api";
import { useNavigate } from "react-router-dom";

const RequestPassword = () => {

  const [email, setEmail] = useState('');
  const navigate = useNavigate()

  const handleRequestPassword = async (userEmail) => {
    if (userEmail) {
      const { data } = await API.postSecureRequest(api.auth.requestPassword, { email: userEmail });
      if (data.result) {
        alert(data.message);
        setEmail("");
        navigate("/login");
      } else {
        alert(data.detail)
      }
    }
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    handleRequestPassword(email);
  }

  return (
    <div className="reset">
      <div className="inner_reset_div">
        <img
          className="reset_logo"
          src={process.env.PUBLIC_URL + "/assets/icons/faithBookLogo.svg"}
          alt=""
        />
        <div className="request_for_password">
          <h4>Request for password</h4>
        </div>
        <div className="Email_ID_provided">
          <h5>Password will be sent to the official Email ID provided</h5>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="inputWrapper">
            <label htmlFor="email">
              Email
            </label>
            <input
              type="email"
              className="input-field"
              name="email"
              autoFocus={true}
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <button className="send_button pointer" type="submit">Send</button>
        </form>
        {/* <div className="connect">
          <h3 className="pointer">Connect with Faithbook Team</h3>
        </div> */}
      </div>
    </div>
  );
};

export default RequestPassword;
