import axios from "axios";
import Auth from "./classAUTH";
import { BASE_URL } from "../api";

class API {
  static getRequest(endPoint) {
    const config = {
      headers: {
        Accept: "application/json",
      },
    };
    return axios.get(BASE_URL + endPoint, config);
  }

  static getSecureRequest(endPoint) {
    const config = {
      headers: {
        Authorization: "Bearer " + Auth.getToken(),
        Accept: "application/json",
      },
    };
    return axios.get(BASE_URL + endPoint, config);
  }

  static postRequest(endPoint, data) {
    const config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    return axios.post(BASE_URL + endPoint, data, config);
  }

  static postSecureRequest(endPoint, data) {
    const config = {
      headers: {
        Authorization: "Bearer " + Auth.getToken(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    return axios.post(BASE_URL + endPoint, data, config);
  }
  
  static postSecureFormDataRequest(endPoint, formData) {
    const config = {
      headers: {
        Authorization: "Bearer " + Auth.getToken(),
        Accept: "*/*",
        "Content-Type": "multipart/form-data" 
      },
    };
    return axios.post(BASE_URL + endPoint, formData, config);
  }

  static putSecureRequest(endPoint, data) {
    const config = {
      headers: {
        Authorization: "Bearer " + Auth.getToken(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    return axios.put(BASE_URL + endPoint, data, config);
  }

  static deleteSecureRequest(endPoint) {
    const config = {
      headers: {
        Authorization: "Bearer " + Auth.getToken(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    return axios.delete(BASE_URL + endPoint, config);
  }
}

export default API;
