import React, { useState } from "react";
import useInfiniteScroll from "../../hooks/useInfiniteScroll";
import useSearch from "../../hooks/useSearch";
import { BASE_URL, api } from "../../utils/api";
import Swal from "sweetalert2";
import useHamburgerStore from "../../store/hamburgerStore";

const Table = ({ query }) => {
  const { refresh, setRefresh, deleteEvent } = useHamburgerStore(
    (state) => state
  );

  const headers = ["Title", "Subtitle", "Author", "Audience", "Action"];

  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const { data, lastElement, loading, error } = useInfiniteScroll(
    BASE_URL + api.hamburger.getAll,
    {
      page: page,
      size: size,
    },
    setPage,
    "results",
    refresh
  );

  const searchData = useSearch(
    BASE_URL + api.hamburger.getAll,
    {
      page: 1,
      size: 100,
      name: query,
    },
    setPage,
    "results"
  );

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#FF3E4D",
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteEvent({ id: id })
          .then((res) => {
            if (res) {
              setRefresh(!refresh);
            }
          })
          .catch((err) => {
            Swal.fire({
              title: "Error!",
              text: err.response.data.detail,
              icon: "error",
              confirmButtonText: "OK",
            });
          });
      }
    });
  };

  return (
    <>
      <table>
        <thead>
          <tr className="head">
            {headers.map((header, index) => {
              return <th key={index}>{header}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {(query ? searchData?.data : data)?.map((hamburger, index) => {
            if (data.length === index + 1) {
              <tr key={index} className="thisTr" ref={lastElement}>
                <td>{hamburger.title || "-"}</td>
                <td>{hamburger.subtitle || "-"}</td>
                <td>{hamburger.authorName || "-"} </td>
                <td>{hamburger.audience || "-"}</td>
                <td className="input-data-icon">
                  <img
                    className="pointer"
                    alt="Approve"
                    src={`${process.env.PUBLIC_URL}/assets/icons/delete.svg`}
                    onClick={() => handleDelete(hamburger.id)}
                  ></img>
                </td>
              </tr>;
            }
            return (
              <tr key={index} className="thisTr">
                <td>{hamburger.title || "-"}</td>
                <td>{hamburger.subtitle || "-"}</td>
                <td>{hamburger.authorName || "-"}</td>
                <td>{hamburger.audience || "-"}</td>
                <td className="input-data-icon">
                  <img
                    className="pointer"
                    alt="Approve"
                    src={`${process.env.PUBLIC_URL}/assets/icons/delete.svg`}
                    onClick={() => handleDelete(hamburger.id)}
                  ></img>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {loading && (
        <div className="loading">
          <span>Loading</span>
        </div>
      )}
      {error && (
        <div className="error">
          <span>Unable to load data</span>
        </div>
      )}
    </>
  );
};

export default Table;
