import React, { useState } from "react";
import useInfiniteScroll from "../../hooks/useInfiniteScroll";
import { BASE_URL, api } from "../../utils/api";
import moment from "moment";
import useSearch from "../../hooks/useSearch";
import useNotificationStore from "../../store/notificationStore";

const Table = ({ query }) => {
  const { setPreView, refresh } = useNotificationStore(store => store)
  const headers = {
    all: ["Title", "Audience", "Date", "Action"],
  };
  const [page, setPage] = useState(1);
    // eslint-disable-next-line 
  const [size, setSize] = useState(10);
  const { data, lastElement, loading, error } = useInfiniteScroll(
    BASE_URL + api.notification.getAll,
    {
      page: page,
      size: size
    },
    setPage,
    "results", refresh
  );

  const searchData = useSearch(
    BASE_URL + api.notification.getAll,
    {
      page: 1,
      size: 100,
      title: query
    },
    setPage,
    "results"
  );

  const handleShare = (node) => {
    setPreView({
      show: true,
      data: node
    })
  };

  return (
    <>
      <table>
        <thead>
          <tr className="head">
            {headers.all.map((th, i) => {
              return <th key={i}>{th}</th>
            })}
          </tr>
        </thead>
        <tbody>
          {(query ? searchData?.data : data)?.map((notification, index) => {
            if (data.length === index + 1) {
              return (
                <tr key={index} ref={lastElement}>
                  <td style={{ width: "50%", wordWrap: true, paddingRight: "20px" }}>{notification.title}</td>
                  <td>{notification.audience}</td>
                  <td>{moment(notification.createdAt).format("DD/MM/YYYY")}</td>
                  <td className="input-data-icon">
                    <img
                      className="pointer"
                      alt="Share"
                      src={`${process.env.PUBLIC_URL}/assets/icons/share.svg`}
                      onClick={() => handleShare(notification)}
                    ></img>
                  </td>
                </tr>
              );
            }
            return (
              <tr key={index}>
                <td style={{ width: "50%", wordWrap: true, paddingRight: "20px" }}>{notification.title}</td>
                <td>{notification.audience}</td>
                <td>{moment(notification.createdAt).format("DD/MM/YYYY")}</td>
                <td className="input-data-icon">
                  <img
                    className="pointer"
                    alt="Share"
                    src={`${process.env.PUBLIC_URL}/assets/icons/share.svg`}
                    onClick={() => handleShare(notification)}
                  ></img>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {loading && <div className="loading"><span>Loading</span></div>}
      {error && <div className="error"><span>Unable to load data</span></div>}
    </>
  );
};

export default Table;
