import React, { useEffect, useRef, useState } from "react";
import useOutsideClickDetect from "../../../../hooks/useOutsideClick";
import "./AddAlbum.scss";
import useGalleryStore from "../../../../store/galleryStore";
import moment from "moment";
import useUploadFileToS3 from "../../../../hooks/useUploadFileToS3";
import useFetch from "../../../../hooks/useFetch"
import { BASE_URL, api } from "../../../../utils/api";
import useAuthStore from "../../../../store/authStore";
import { useLocation, useNavigate } from "react-router-dom";
import { constants } from "../../../../utils/constants";
import Swal from "sweetalert2";

const AddAlbum = () => {
  const { editable, setEditableData, refresh, setRefresh, updateEvent, addEvent } = useGalleryStore(store => store);
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const navigate = useNavigate()

  const { user } = useAuthStore(store => store)
  const listingData = useAuthStore(state=>state.listingData)
  // Refs & States
  const formRef = useRef(null);
  useOutsideClickDetect(formRef, setEditableData);

  const initialState = {
    audience: "",
    // new
    region: "",
    diocese: "",
    deanery: "",
    parish: "",
    // new
    audienceType: "",
    author: "",
    authorName: "",
    albumName: "",
    eventDate: "",
    eventTime: "",
    description: "",
    images: []
  };

  const formFor = {
    "region": 1,
    "diocese": 2,
    "deanery": 3,
    "parish": 4,
  }
  const [labelColor, setLabelColor] = useState(1);

  useEffect(() => {
    if (editable?.data) {
      setLabelColor(formFor[editable.data.audienceType])
      setValues({
        ...editable?.data,
        eventDate: moment(editable?.data?.eventDate).format("YYYY-MM-DD"),
        eventTime: moment(editable?.data?.eventDate).format("hh:mm")
      });
    }
    else {
      setValues(initialState);
      setLabelColor(null)
    }
    // eslint-disable-next-line 
  }, [editable.data])
  const [values, setValues] = useState(initialState);
  const { audience, audienceType,
    // new
    region,
    diocese,
    deanery,
    parish,
    // new 
    albumName, eventDate, eventTime, description } = values;


  const filePath = `${constants.AWS_S3.gallery}`
  const [files, setFiles] = useState([])
  const { data } = useUploadFileToS3(files, filePath)
  /* --------------------------Handlers-------------------------*/
  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const inputRef = useRef(null)

  const handleFileChange = (e) => {
    const fileRes = e.target.files;
    const file = []
    if (fileRes.length) {
      for (let i = 0; i < fileRes.length; i++) {
        const element = fileRes[i];
        if (element.name) { file.push(element); files.push(element) }
        setValues({ ...values, images: [...values.images, ...file] })
      }
      setFiles([...files])
    }
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    const type = Object.keys(formFor)[+labelColor - 1];
    const body = {
      ...values,
      audienceType: type,
      author: user.id,
      authorName: user.name,
      eventDate: moment(`${eventDate} ${eventTime}`).utc().format()
    }
    const { updatedAt, createdAt, id, ...rest } = body
    const params = { id: id }
    if (queryParams.has("id")) {
      updateEvent(params, { ...rest, images: [...editable?.data?.images, ...data], audience: values[audienceType] }).then((res) => {
        if (res) {
          Swal.fire({
            title: 'Success!',
            text: 'Record updated successfully',
            icon: 'success',
            confirmButtonText: 'OK'
          })
          setRefresh(!refresh)
          setEditableData({
            show: false,
            data: res.data.result
          })
          navigate("/gallery")
        }
      }).catch((err) => {
        Swal.fire({
          title: 'Error!',
          text: err.response.data.detail,
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
    }
    else {
      addEvent({ ...body, images: [...data], audience: values[audienceType] }).then((res) => {
        if (res) {
          Swal.fire({
            title: 'Success!',
            text: 'Record added successfully',
            icon: 'success',
            confirmButtonText: 'OK'
          })
          setRefresh(!refresh)
          setEditableData({
            show: false,
            data: null,
          });
        }
      }).catch((err) => {
        Swal.fire({
          title: 'Error!',
          text: err.response.data.detail,
          icon: 'error',
          confirmButtonText: 'OK'
        })
      });
    }
  };

  // const listingData = useFetch(`${BASE_URL}${api.listing.listByRole}?role=${user.role}&value=${user[user.role.toLowerCase()]}`, true)


  const showSelection = {
    region: ['region', 'diocese', 'deanery', 'parish'],
    diocese: ['diocese', 'deanery', 'parish'],
    deanery: ['deanery', 'parish'],
    parish: ['parish']
  }

  const regionList = listingData && Object.keys(listingData)
  const dioceseList = region && listingData && listingData[region] && Object.keys(listingData[region]) || []
  const deaneryList = region && diocese && listingData && listingData[region] && listingData[region][diocese] && Object.keys(listingData[region][diocese]) || []
  const parishList = region && diocese && deanery && listingData && listingData[region] && listingData[region][diocese] && listingData[region][diocese][deanery] && Object.keys(listingData[region][diocese][deanery]) || []


  const handleLabelColor = (button) => {
    setValues({ ...values, audienceType: Object.keys(formFor)[+labelColor - 1] })
    setLabelColor(button);
  };
  return (
    <>
      {editable?.show && (
        <div className="gallery-wrapper">
          <form className="gallery-form" ref={formRef} onSubmit={handleSubmit}>
            <div className="gallery-header">
              <span></span>
              <span className="btn-close"><img src={process.env.PUBLIC_URL + "/assets/icons/charm_cross.svg"} alt="" onClick={() => setEditableData(false)} /></span>
              <h4 className="gallery-heading">Album details</h4>
              <button
                className="save"
                type="submit"
              >
                {editable?.data ? "Update" : "Save"}
              </button>
            </div>

            <div className="gallery-content">
              {/* -------------------TYPE OF AUDIENCE------------------ */}

              <h5 className="select-audience">Select audience</h5>
              <div className="select-audience-type">
                {/* LABEL 1 */}
                <label
                  htmlFor="1"
                  onClick={!queryParams.has("id") ? () => handleLabelColor(1) : () => { }}
                  style={{
                    background: labelColor === 1 ? "#194459" : "white",
                    color: labelColor === 1 ? "white" : "#5b686e",
                  }}
                >
                  <input className="audience-input" type="radio" id="1" />
                  <span>Region</span>
                </label>

                {/* LABEL2 */}
                <label
                  htmlFor="2"
                  onClick={!queryParams.has("id") ? () => handleLabelColor(2) : () => { }}
                  style={{
                    background: labelColor === 2 ? "#194459" : "white",
                    color: labelColor === 2 ? "white" : "#5b686e",
                  }}
                >
                  <input className="audience-input" type="radio" id="2" />
                  <span>Diocese</span>
                </label>
                {/* LABEL3 */}
                <label
                  htmlFor="3"
                  onClick={!queryParams.has("id") ? () => handleLabelColor(3) : () => { }}
                  style={{
                    background: labelColor === 3 ? "#194459" : "white",
                    color: labelColor === 3 ? "white" : "#5b686e",
                  }}
                >
                  <input className="audience-input" type="radio" id="3" />
                  <span>Zone</span>
                </label>

                {/* LABEL4 */}
                <label
                  htmlFor="4"
                  onClick={!queryParams.has("id") ? () => handleLabelColor(4) : () => { }}
                  style={{
                    background: labelColor === 4 ? "#194459" : "white",
                    color: labelColor === 4 ? "white" : "#5b686e",
                  }}
                >
                  <input className="audience-input" type="radio" id="4" />
                  <span>Parish</span>
                </label>
              </div>

              {/* NEW NOTIFICATIONS INPUTS */}
              <div className="gallery-inputs">
                {queryParams.has("id") && <select name="audience" value={audience} onChange={handleChange} className="capitalize" required disabled>
                  <option value={audience} className="capitalize">{audience}</option>
                </select>}

                {/* NEW */}
                {!queryParams.has("id") &&
                  <>
                    {showSelection.region.includes(values.audienceType) && <select name="region" onChange={handleChange} value={region} required>
                      <option value="">Select Region</option>
                      {regionList?.map((item, i) => {
                        if (item === null || item === "null") return null
                        return <option key={i} value={item}>{item}</option>
                      })}
                    </select>}
                    {showSelection.diocese.includes(values.audienceType) && <select name="diocese" onChange={handleChange} value={diocese} required>
                      <option value="">Select Diocese</option>
                      {dioceseList?.map((item, i) => {
                        if (item === null || item === "null") return null
                        return <option key={i} value={item}>{item}</option>
                      })}
                    </select>}
                    {showSelection.deanery.includes(values.audienceType) && <select name="deanery" onChange={handleChange} value={deanery} required>
                      <option value="">Select Deanery</option>
                      {deaneryList?.map((item, i) => {
                        if (item === null || item === "null") return null
                        return <option key={i} value={item}>{item}</option>
                      })}
                    </select>}
                    {showSelection.parish.includes(values.audienceType) && <select name="parish" onChange={handleChange} value={parish} required>
                      <option value="">Select Parish</option>
                      {parishList?.map((item, i) => {
                        if (item === null || item === "null") return null
                        return <option key={i} value={item}>{item}</option>
                      })}
                    </select>}
                  </>
                }
                {/* NEW */}

                <input
                  type="text"
                  placeholder="Album Name"
                  name="albumName"
                  value={albumName}
                  onChange={handleChange}
                  required
                />
                <input
                  type="date"
                  placeholder="Event Date"
                  name="eventDate"
                  value={eventDate}
                  onChange={handleChange}
                  required
                />
                <input
                  type="time"
                  placeholder="Event Time"
                  name="eventTime"
                  value={eventTime}
                  onChange={handleChange}
                  required
                />

                <textarea
                  type="text"
                  placeholder="Description"
                  name="description"
                  value={description}
                  onChange={handleChange}
                  required
                />
                <div className="img-wrapper">
                  <label className="card-body" htmlFor="0">
                    <input id="0" style={{ display: "none" }} ref={inputRef} multiple type="file" onChange={handleFileChange} />
                    <img
                      className="add-img"
                      src={`${process.env.PUBLIC_URL}/assets/icons/img-add.svg`}
                      alt="Add"
                    />
                    <span >+Add Image</span>
                  </label>

                  {values?.images?.map((img, i) => {
                    if (img.name) {
                      return <label className="card-body2" htmlFor={i} key={i}>
                        <img
                          className=""
                          src={URL.createObjectURL(img)}
                          alt="Add"
                        />
                      </label>
                    }
                    return <label className="card-body2" htmlFor={i} key={i}>
                      <img
                        className=""
                        src={img}
                        alt="Add"
                      />
                    </label>
                  })}
                </div>
              </div>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default AddAlbum;
