import React, { useRef } from "react";
import "./ShowNotification.scss";
import useOutsideClickDetect from "../../../../hooks/useOutsideClick";
import useNotificationStore from "../../../../store/notificationStore";

const ShowNotification = () => {
  const formRef = useRef(null);
  const { preView, setPreView } = useNotificationStore(store => store)
  useOutsideClickDetect(formRef, setPreView);
  console.log(preView.data)
  const { title, body, images, audience } = preView?.data
  return (
    <div className="show-notification-wrapper">
      <div ref={formRef} className="show-notification-maindiv">
        <div className="show-notification-content">
          <div className="show-notification-header">
            <span></span>
            <span className="btn-close"><img src={process.env.PUBLIC_URL + "/assets/icons/charm_cross.svg"} alt="" onClick={() => setPreView(false)} /></span>
            <h4 className="show-notification-heading">Notification</h4>
          </div>
          <img
            className="show-notification-topimage"
            src={images && images[0] ? images[0] : `${process.env.PUBLIC_URL}/assets/images/notification.svg`}
            alt=""
          />

          <h4 className="show-notification-title">{title}</h4>
          <p>{body}
          </p>
          <h5>Audience</h5>
          <h4 className="show-notification-regionname">{audience}</h4>
        </div>
      </div>
    </div>
  );
};

export default ShowNotification;
