import React, { useEffect, useState } from "react";
import "./Home.scss";
import useFetch from "../../hooks/useFetch";
import { BASE_URL, api } from "../../utils/api";
import Birthdays from "./Birthdays";
import Youth from "./Youth";
import useAuthStore from "../../store/authStore";

const Home = () => {
  const { data } = useFetch(BASE_URL + api.dashboard.stats, true);
  const getListingData = useAuthStore(state=>state.getListingData)
  const [stats, setStats] = useState(null);

  useEffect(() => {
    if (data) {
      setStats(data.result.stats)
    }
  }, [data])

  useEffect(() => {
    getListingData();
  }, [])
  

  return (
    <div className="wrapper">
      <div className="dashboard">
        <div className="stats-counter">
          <div className="count-div">
            {stats && Object.entries(stats?.listing).map(([key, count], i) => {
              return <div className="counter-card" key={i}>
                <span>{count}</span>
                <h4>{key}</h4>
              </div>
            })}
          </div>

          <div className="count-div">
            {stats && Object.entries(stats?.youth).map(([key, count], i) => {
              return <div className="counter-card" key={i}>
                <span>{count}</span>
                <h4>{key}</h4>
              </div>
            })}
          </div>
        </div>

        {/* Youth Section & Birthdays */}
        <div className="youth-listing">
          <Youth />
          <Birthdays />
        </div>
      </div>
    </div>
  );
};

export default Home;
