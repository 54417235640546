import React from "react";
import "./FamilyDetails.scss";
import useYouthSectionStore from "../../../store/youthSectionStore";

const FamilyDetails = () => {
  const { setPreView, preView } = useYouthSectionStore(state => state)
  const { motherName, fatherName, brothersCount, sistersCount } = preView?.data
  return (
    <div className="family-details-wrapper">
      <div className="parents-names">
        {/* --------Father-------- */}
        <div>
          <div className="father-heading">
            <img
              className="father"
              src={`${process.env.PUBLIC_URL}/assets/icons/father.svg`}
              alt="back"
            />
            <h6>Father's Name</h6>
          </div>
          <input type="text" placeholder="Fathername" value={fatherName} readOnly />
        </div>

        {/* ------------Mother--------- */}
        <div>
          <div className="mother-heading">
            <img
              className="mother"
              src={`${process.env.PUBLIC_URL}/assets/icons/mother.svg`}
              alt="back"
            />
            <h6>Mother's Name</h6>
          </div>
          <input type="text" placeholder="Mothername" value={motherName} readOnly />
        </div>
      </div>

      <div className="siblings">
        {/* --------Brother-------- */}
        <div>
          <div className="brother-heading">
            <img
              className="brother"
              src={`${process.env.PUBLIC_URL}/assets/icons/brother.svg`}
              alt="back"
            />
            <h6>No. of brothers</h6>
          </div>
          <input type="text" placeholder="No.of brothers" value={brothersCount} readOnly />
        </div>

        {/* ------------Sister--------- */}
        <div>
          <div className="sister-heading">
            <img
              className="sister"
              src={`${process.env.PUBLIC_URL}/assets/icons/sister.svg`}
              alt="back"
            />
            <h6>No. of sisters</h6>
          </div>
          <input type="text" placeholder="No. of sisters" value={sistersCount} readOnly />
        </div>
      </div>
    </div>
  );
};

export default FamilyDetails;
