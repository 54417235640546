import React from "react";
import AppEntry from "./routers/AppEntry";
import { BrowserRouter } from "react-router-dom";
import OpenRouter from "./routers/OpenRouter";
import "./App.css"
import "./css/responsive.css"
import useAuthStore from "./store/authStore";

function App() {
  const { auth } = useAuthStore((state) => state);

  if (auth) {
    return (
      <BrowserRouter>
        <AppEntry auth={auth} />
      </BrowserRouter>
    )
  } else {
    return (
      <BrowserRouter>
        <OpenRouter />
      </BrowserRouter>
    );
  }
}

export default App;
