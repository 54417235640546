import React, { useEffect, useRef, useState } from "react";
import "./EditProfile.scss";
import useAuthStore from "../../../../store/authStore";
import API from "../../../../utils/classes/classAPI";
import { BASE_URL, api } from "../../../../utils/api";
import { constants } from "../../../../utils/constants";
import useUploadFileToS3 from "../../../../hooks/useUploadFileToS3";
import Swal from "sweetalert2";

const EditProfile = ({ setShowEditProfile }) => {
  const { user, updateUser } = useAuthStore((state) => state);
  const formRef = useRef(null);

  const initialValues = {
    name: "",
    description: "",
  }
  const [values, setValues] = useState(initialValues)
  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value })
  }

  useEffect(() => {
    setValues(user)
  }, [])

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!values.name || !values.description || !values.avatar) {
      Swal.fire({
        title: 'Alert!',
        text: 'All values are required',
        icon: 'warning', // success, info, error, question, warning
        confirmButtonText: 'OK'
      })
      return;
    }
    updateUser(user.id, { ...values, avatar: data?.length ? [...data] : values?.avatar }).then((res) => {
      if (res) {
        Swal.fire({
          title: 'Success!',
          text: 'Your profile has updated successfully',
          icon: 'success',
          confirmButtonText: 'OK'
        })
        setShowEditProfile(false)
      }
    }).catch((err) => {
      Swal.fire({
        title: 'Error!',
        text: err.response.data.detail,
        icon: 'error',
        confirmButtonText: 'OK'
      })
    })
  }

  const filePath = `${constants.AWS_S3.profilePics}`
  const [files, setFiles] = useState([])
  const inputRef = useRef(null)
  const handleSelectFile = () => inputRef && inputRef?.current && inputRef?.current?.click()
  const handleFileChange = (e) => {
    const fileRes = e.target.files[0];
    setValues({ ...values, avatar: fileRes })
    setFiles([fileRes])
  }
  const { data } = useUploadFileToS3(files, filePath)

  return (
    <div className="edit-profile-wrapper">
      <span className="close">
        <img
          className="charmCross-icon"
          src={process.env.PUBLIC_URL + "/assets/icons/charm_cross.svg"}
          alt=""
          onClick={() => setShowEditProfile(false)}
        />
      </span>
      <form ref={formRef} className="edit-profile-maindiv" onSubmit={handleSubmit}>
        <div className="edit-profile-content">
          <div className="edit-profile-header">
            <h4 className="edit-profile-heading">My profile</h4>
          </div>
          <div className="edit-profile-pic" onClick={handleSelectFile}>
            <input type="file" ref={inputRef} onChange={handleFileChange} style={{ display: 'none' }} />
            <img
              className="base-image"
              src={typeof values?.avatar?.name === "string" ? URL.createObjectURL(values?.avatar) : values?.avatar}
              alt=""
            />
            <span className="overlay-image2">
              <img
                src={process.env.PUBLIC_URL + "/assets/icons/camera.svg"}
                alt=""
              />
            </span>
          </div>

          <div className="edit-profile-data">
            <input type="text" className="edit-profile-name" name="name" onChange={handleChange} value={values.name} readOnly />
            <textarea maxLength={500} style={{ resize: "none" }} name="description" placeholder="Write some words about yourself (not more than 500 characters)" value={values.description} onChange={handleChange}></textarea>
          </div>

          <label
            htmlFor="2"
            className="button-lable"
            onClick={() => setShowEditProfile(true)}
          >
            <button className="save" type="submit">Save</button>
          </label>
        </div>
      </form>
    </div>
  );
};

export default EditProfile;
