import React, { useRef } from "react";
import "./Notification.scss";
import useOutsideClickDetect from "../../../../hooks/useOutsideClick";
import useFetch from "../../../../hooks/useFetch";
import { BASE_URL, api } from "../../../../utils/api";
import useAuthStore from "../../../../store/authStore";

const Notification = ({ showModal, setShowModal }) => {

  const { user } = useAuthStore(state => state)
  const { data } = useFetch(BASE_URL + api.notification.getAll, true)

  const handleShowModal = () => {
    setShowModal(false);
  };

  const formRef = useRef(null);
  useOutsideClickDetect(formRef, setShowModal);
  if (!showModal) return null;
  return (
    <div className="notification-wrapper">
      <form ref={formRef} className="notification-maindiv">
        {/* -------------------NOTIFICATION HEADER ------------------- */}
        <div className="notification-content">
          {/* --------------HEADER------------- */}
          <div className="notification-header">
            <h4 className="notification-heading">Notification</h4>
            <img
              onClick={handleShowModal}
              className="charmCross-icon"
              src={process.env.PUBLIC_URL + "/assets/icons/charm_cross.svg"}
              alt=""
            />
          </div>
          {data.results.filter((ele) => ele.audience.toLowerCase() === user.role).map((item, index) => {
            return (
              <div className="renderdiv" key={index}>
                <div className="notification-imgdiv">
                  {item.image && <img src={"data:image/png;base64," + item.image} alt="" />}
                </div>
                <div className="notification-data">
                  <h4>{item.title}</h4>
                  <p className="desc">{item.body}</p>
                </div>
              </div>
            );
          })}
        </div>
      </form>
    </div>
  );
};

export default Notification;
