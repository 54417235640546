import { create } from "zustand";
import { api } from "../utils/api";
import API from "../utils/classes/classAPI";

const initialState = {
    preView: {
        show: false,
        data: null
    },
    // editable: {
    //     show: false,
    //     data: null
    // },
    refresh: false
}

const youthSectionStore = (set) => ({
    ...initialState,

    setPreView: (node) => {
        set((state) => ({ ...state, preView: node }))
    },

    setRefresh: (bool) => {
        set((state) => ({ ...state, refresh: bool }))
    },

    activateYouth: async (params) => {
        const [key, value] = Object.entries(params)[0]
        const body = { status: "active" }
        const { data, error } = await API.putSecureRequest(`${api.user.updateUser}?${key}=${value}`, body);
        return { data, error }
    },

    inActivateYouth: async (params) => {
        const [key, value] = Object.entries(params)[0]
        const body = { status: "inactive" }
        const { data, error } = await API.putSecureRequest(`${api.user.updateUser}?${key}=${value}`, body);
        return { data, error }
    }
});

const useYouthSectionStore = create(youthSectionStore)

export default useYouthSectionStore;