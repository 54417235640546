import React, { useEffect, useState } from "react";
import { BASE_URL, api } from "../../utils/api";
import useInfiniteScroll from "../../hooks/useInfiniteScroll";
import useSearch from "../../hooks/useSearch";
import moment from "moment";
import useGalleryStore from "../../store/galleryStore";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const Table = ({ query }) => {
  const { setPreView, setEditableData, refresh, setRefresh, deleteEvent, editable } = useGalleryStore(state => state)
  const navigate = useNavigate()
  const headers = ["Album Name", "Description", "Event Date", "Action"];

  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const { data, lastElement, loading, error } = useInfiniteScroll(
    BASE_URL + api.gallery.getAll,
    {
      page: page,
      size: size
    },
    setPage,
    "results", refresh
  );

  const searchData = useSearch(
    BASE_URL + api.gallery.getAll,
    {
      page: 1,
      size: 100,
      albumName: query
    },
    setPage,
    "results"
  );

  const handleShare = (node) => {
    setPreView({
      show: true,
      data: node
    })
  }

  const handleEdit = (node) => {
    setEditableData({
      show: true,
      data: node
    })
    navigate("?id=" + node.id)
  }

  const handleDelete = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#FF3E4D",
      confirmButtonText: 'Delete'
    }).then((result) => {
      if (result.isConfirmed) {
        deleteEvent({ id: id }).then((res) => {
          if (res) {
            setRefresh(!refresh)
          }
        }).catch((err) => {
          Swal.fire({
            title: 'Error!',
            text: err.response.data.detail,
            icon: 'error',
            confirmButtonText: 'OK'
          })
        })
      }
    })
  }

  return (
    <>
      <table>
        <thead>
          <tr className="head">
            {headers.map((th, i) => {
              return <th key={i}>{th}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {(query ? searchData?.data : data)?.map((gallery, index) => {
            if (data.length === index + 1) {
              return (
                <tr key={index} ref={lastElement}>
                  <td>{gallery.albumName}</td>
                  <td>{gallery?.description ? gallery?.description?.slice(0, 30) : "-"}</td>
                  <td>{moment(gallery.eventDate).format("DD/MM/YYYY")}</td>
                  <td className="input-data-icon">
                    <img
                      className="pointer"
                      alt="Share"
                      src={`${process.env.PUBLIC_URL}/assets/icons/share.svg`}
                      onClick={() => handleShare(gallery)}
                    ></img>
                    <img
                      className="pointer"
                      alt="Approve"
                      src={`${process.env.PUBLIC_URL}/assets/icons/edit.svg`}
                      onClick={() => handleEdit(gallery)}
                    ></img>
                    <img
                      className="pointer"
                      alt="Approve"
                      src={`${process.env.PUBLIC_URL}/assets/icons/delete.svg`}
                      onClick={() => handleDelete(gallery.id)}
                    ></img>
                  </td>
                </tr>
              );
            }
            return (
              <tr key={index}>
                <td>{gallery.albumName}</td>
                <td>{gallery?.description ? gallery?.description?.slice(0, 100) + "..." : "-"}</td>
                <td>{moment(gallery.eventDate).format("DD/MM/YYYY")}</td>
                <td className="input-data-icon">
                  <img
                    className="pointer"
                    alt="Share"
                    src={`${process.env.PUBLIC_URL}/assets/icons/share.svg`}
                    onClick={() => handleShare(gallery)}
                  ></img>
                  <img
                    className="pointer"
                    alt="Approve"
                    src={`${process.env.PUBLIC_URL}/assets/icons/edit.svg`}
                    onClick={() => handleEdit(gallery)}
                  ></img>
                  <img
                    className="pointer"
                    alt="Approve"
                    src={`${process.env.PUBLIC_URL}/assets/icons/delete.svg`}
                    onClick={() => handleDelete(gallery.id)}
                  ></img>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {loading && <div className="loading"><span>Loading</span></div>}
      {error && <div className="error"><span>Unable to load data</span></div>}
    </>

  );
};

export default Table;
