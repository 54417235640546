import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import useInfiniteScroll from "../../../hooks/useInfiniteScroll";
import { BASE_URL, api } from "../../../utils/api";
import useSearch from "../../../hooks/useSearch";
import useRoleManagementStore from "../../../store/roleManagementStore";
import Swal from "sweetalert2";

const Region = ({ query }) => {
    const headers = ["Name", "Email", "Region", "Diocese", "Action"];
    const { refresh, setRefresh, setPreView, setEditableData, deleteEvent } = useRoleManagementStore(store => store)
    const navigate = useNavigate();
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(10);
    const { data, lastElement, loading, error } = useInfiniteScroll(
        BASE_URL + api.user.getAll,
        {
            page: page,
            size: size,
            role: "region",
            is_deleted: false,
            web: true
        },
        setPage,
        "results", refresh
    );

    const searchData = useSearch(
        BASE_URL + api.user.getAll,
        {
            page: 1,
            size: 100,
            role: "region",
            name: query,
            is_deleted: false,
            web: true
        },
        setPage,
        "results"
    );

    const handleUpdate = (node) => {
        setEditableData({
            show: true,
            data: node
        })
        navigate(`?id=${node.id}`);
    };

    const handleDelete = (node) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            showCancelButton: true,
            confirmButtonColor: "#FF3E4D",
            confirmButtonText: 'Delete'
        }).then((result) => {
            if (result.isConfirmed) {
                deleteEvent({ id: node.id }).then((res) => {
                    if (res) {
                        setRefresh(!refresh)
                    }
                }).catch((err) => {
                    Swal.fire({
                        title: 'Error!',
                        text: err.response.data.detail,
                        icon: 'error',
                        confirmButtonText: 'OK'
                    })
                })
            }
        })
    };

    const handleShare = (node) => {
        setPreView({
            show: true,
            data: node
        })
    };

    return (
        <>
            <table>
                <thead>
                    <tr className="head">
                        {headers.map((th, i) => {
                            return <th key={i}>{th}</th>
                        })}
                    </tr>
                </thead>
                <tbody>
                    {(query ? searchData?.data : data)?.map((user, index) => {
                        if (data.length === index + 1) {
                            return <tr key={index} ref={lastElement}>
                                <td>{user.name}</td>
                                <td>{user.email}</td>
                                {/* <td className="capitalize">{user.role}</td> */}
                                <td>{user.region || "-"}</td>
                                <td>{user.diocese || "-"}</td>
                                <td className="input-data-icon">
                                    <img
                                        className="pointer"
                                        alt="Share"
                                        src={`${process.env.PUBLIC_URL}/assets/icons/share.svg`}
                                        onClick={() => handleShare(user)}
                                    ></img>
                                    <img
                                        className="pointer"
                                        alt="Update"
                                        src={`${process.env.PUBLIC_URL}/assets/icons/edit.svg`}
                                        onClick={() => handleUpdate(user)}
                                    ></img>
                                    <img
                                        className="pointer"
                                        alt="Delete"
                                        src={`${process.env.PUBLIC_URL}/assets/icons/delete.svg`}
                                        onClick={() => handleDelete(user)}
                                    ></img>
                                </td>
                            </tr>
                        }
                        return (
                            <tr key={index}>
                                <td>{user.name}</td>
                                <td>{user.email}</td>
                                {/* <td className="capitalize">{user.role}</td> */}
                                <td>{user.region || "-"}</td>
                                <td>{user.diocese || "-"}</td>
                                <td className="input-data-icon">
                                    <img
                                        className="pointer"
                                        alt="Share"
                                        src={`${process.env.PUBLIC_URL}/assets/icons/share.svg`}
                                        onClick={() => handleShare(user)}
                                    ></img>
                                    <img
                                        className="pointer"
                                        alt="Update"
                                        src={`${process.env.PUBLIC_URL}/assets/icons/edit.svg`}
                                        onClick={() => handleUpdate(user)}
                                    ></img>
                                    <img
                                        className="pointer"
                                        alt="Delete"
                                        src={`${process.env.PUBLIC_URL}/assets/icons/delete.svg`}
                                        onClick={() => handleDelete(user)}
                                    ></img>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            {loading && <div className="loading"><span>Loading</span></div>}
            {error && <div className="error"><span>Unable to load data</span></div>}
        </>

    );
};

export default Region;
