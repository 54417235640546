export const constants = {
    auth: {
        token: "t0k3n",
    },
    role: {
        ADMIN: "admin",
        REGION: "region",
        DIOCESE: "diocese",
        DEANERY: "deanery",
        PARISH: "parish"
    },
    AWS_S3: {
        listing: "Documents/Listings/",
        profilePics: "ProfilePics/",
        gallery: "Gallery/",
        notification: "NotificationAssets/",
        campus: "CampusConnectAssets/",
        news: "NewsAssets/",
        announcement: "AnnouncementAssets/"
    }
}