import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import API from "../utils/classes/classAPI";

const useUploadFileToS3 = (fileList, filePath, feedFile) => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(null);
  const [percentage, setPercentage] = useState(0);

  // function getExtension(fileName) {
  //   const extension = fileName?.split(".").pop();
  //   return `.${extension}`;
  // }

  const endpoint = "/upload";
  const uploadFile = async (body) => {
    const { data, error } = await API.postSecureFormDataRequest(endpoint, body);
    return { data, error };
  };
  const handleUploadFile = (file) => {
    const formData = new FormData();
    formData.append("file", file);
    setLoading(true)
    uploadFile(formData).then((urlData) => {
      console.log("data upload file data", urlData);
      Swal.fire({
        position: "bottom-end",
        text: "File uploaded successfully.",
        showConfirmButton: false,
        timer: 1500,
        width: 500,
        background: "#194459",
        color: "#FFF",
      });

      setData((prev) => {
        return [...new Set([...prev, urlData?.url])];
      });
      setLoading(false)
    }).catch((err)=>{
      setError(err);
      setLoading(false);
    });
  };

  useEffect(() => {
    if (fileList?.length) {
      fileList?.forEach((file) => {
        handleUploadFile(file);
      });
    }
  }, [fileList]);

  return { loading, data, error, percentage };
};

export default useUploadFileToS3;
