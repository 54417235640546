import React, { useRef, useState } from 'react'
import EditModal from './Modals/EditModal/EditModal'
import Tooltip from './Modals/Tooltip/Tooltip';
import useListingStore from '../../store/listingStore';
import useHoverOut from '../../hooks/useHoverOut';
import Swal from 'sweetalert2';
import useAuthStore from '../../store/authStore';

const ListNode = ({ name, selector, roleType, value, handleSelect, setShowModal }) => {
    const { user } = useAuthStore(state => state)
    console.log(user)
    let active = name === selector[roleType]

    const { setListingTrigger, listingTrigger, deleteListing, setNodeInfo } = useListingStore((store) => store)
    const [showEditOptions, setShowEditOptions] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false)
    const handleOptionsToggle = () => {
        setShowEditOptions(true)
    }

    const handleDelete = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            showCancelButton: true,
            confirmButtonColor: "#FF3E4D",
            confirmButtonText: 'Delete'
        }).then((result) => {
            if (result.isConfirmed) {
                deleteListing({ key: roleType, value: name }).then((data) => {
                    if (data) {
                        Swal.fire({
                            title: 'Success!',
                            text: "Record deleted successfully",
                            icon: 'success',
                            confirmButtonText: 'OK'
                        })
                        setShowEditOptions(false)
                        setListingTrigger(!listingTrigger)
                    }
                }).catch((err) => {
                    if (err) {
                        Swal.fire({
                            title: 'Error!',
                            text: err.response.data.detail,
                            icon: 'error',
                            confirmButtonText: 'OK'
                        })
                    }
                })
            }
        })
    }

    const handleUpdate = () => {
        setNodeInfo({ "roleType": roleType });
        setShowModal(true)
    }

    const tooltipRef = useRef(null)
    useHoverOut(tooltipRef, setShowTooltip)

    return (
        <>
            {roleType === "parish" && showTooltip && <Tooltip value={value} setState={setShowTooltip} />}
            <div ref={tooltipRef} onClick={handleSelect} className={"td " + (active && (roleType === "parish" ? "p" : "") + "active")} >
                {user.role === 'admin' && active && <span className="menu" onClick={handleOptionsToggle}>
                    <img src={process.env.PUBLIC_URL + "/assets/icons/" + (active && roleType === "parish" ? "dots_blue.svg" : "dots.svg")} alt="" />
                </span>}
                <span className="name" onMouseOver={() => setShowTooltip(true)}>{name}</span>
                {active && roleType !== "parish" && <span className="arrow">
                    <img src={process.env.PUBLIC_URL + "/assets/icons/right_arrow.svg"} alt="" />
                </span>}
            </div>
            {showEditOptions && <EditModal setState={setShowEditOptions} handleDelete={handleDelete} handleUpdate={handleUpdate} />}
        </>
    )
}

export default ListNode