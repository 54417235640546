import React from "react";
import { DebounceInput } from "react-debounce-input";
import { useNavigate } from "react-router-dom";
import useHamburgerStore from "../../store/hamburgerStore";

const Header = ({ setQuery }) => {
  const navigate = useNavigate();
  const handleSearch = (e) => setQuery(e.target.value);
  const setEditableData = useHamburgerStore((state) => state.setEditableData);
  const handleShowAdd = () => {
    navigate("/hamburger");
    setEditableData({ show: true, data: null });
  };

  return (
    <div className="head-wrapper">
      <div className="gallery-roles">
        <h3>Hamburger Configurations</h3>
      </div>
      <div className="gallery-search-wrapper">
        <div className="rectangle-group">
          <img
            className="search-icon"
            src={process.env.PUBLIC_URL + "/assets/icons/search.svg"}
            alt=""
          />
          <DebounceInput
            minLength={2}
            debounceTimeout={500}
            className="text-area"
            placeholder={"Search"}
            onChange={handleSearch}
          />
        </div>
        <button className="add-btn pointer" onClick={handleShowAdd}>
          +Add new
        </button>
      </div>
    </div>
  );
};

export default Header;
