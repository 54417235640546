import { create } from "zustand";
// import { devtools, persist } from 'zustand/middleware'
import API from "../utils/classes/classAPI";
import { api } from "../utils/api";

const initialNode = {
    roleType: null,
    parish: null,
    region: null,
    diocese: null,
    deanery: null
}
const initialState = {
    listingTrigger: false,
    nodeInfo: initialNode
}

const listingStore = (set) => ({
    ...initialState,

    setListingTrigger: (bool) => {
        set(() => ({ listingTrigger: bool }))
    },

    setNodeInfo: (node) => {
        set((state) => ({ nodeInfo: { ...state.nodeInfo, [Object.entries(node)[0][0]]: Object.entries(node)[0][1] } }))
    },

    addListing: async (body) => {
        const { data, error } = await API.postSecureRequest(api.listing.post, body);
        return { data, error }
    },

    updateListing: async (body) => {
        const { data, error } = await API.putSecureRequest(api.listing.put, body);
        return { data, error }
    },

    deleteListing: async (params) => {
        const { data, error } = await API.deleteSecureRequest(`${api.listing.delete}?${params.key}=${params.value}`);
        return { data, error }
    },

    feedFile: async (filePath) => {
        const { data, error } = await API.postSecureRequest(api.listing.feed, { "listingLink": [filePath] });
        return { data, error }
    }
});

const useListingStore = create(listingStore)

export default useListingStore;