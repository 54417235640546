import React, { useRef } from "react";
import "./ShowMember.scss";
import useRoleManagementStore from "../../../../store/roleManagementStore.js";
import { useNavigate } from "react-router-dom";
import useOutsideClickDetect from "../../../../hooks/useOutsideClick.jsx";
import Swal from "sweetalert2";

const ShowMember = () => {
  const formRef = useRef(null);
  const navigate = useNavigate()
  const { preView, setPreView, setEditableData, refresh, setRefresh, deleteEvent } = useRoleManagementStore(store => store)
  useOutsideClickDetect(formRef, setPreView);

  const { data } = preView

  const handleDelete = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#FF3E4D",
      confirmButtonText: 'Delete'
    }).then((result) => {
      if (result.isConfirmed) {
        deleteEvent({ id: data.id }).then((res) => {
          if (res) {
            setRefresh(!refresh)
          }
        }).catch((err) => {
          Swal.fire({
            title: 'Error!',
            text: err.response.data.detail,
            icon: 'error',
            confirmButtonText: 'OK'
          })
        })
        setPreView(false)
      }
    })
  };

  const handleEdit = () => {
    setEditableData(preView)
    navigate("?id=" + data.id)
    setPreView(false)
  }

  return (
    <div className="showmember-wrapper">
      <div ref={formRef} className="showmember-maindiv">
        {/* ------------HEADERS------------ */}
        <div className="showmember-header">
          <span></span>
          <span className="btn-close"><img src={process.env.PUBLIC_URL + "/assets/icons/charm_cross.svg"} alt="" onClick={() => setPreView(false)} /></span>
          <h4>Member details</h4>
          <div className="img-div">
            <img
              className="search-icon"
              src={process.env.PUBLIC_URL + "/assets/icons/edit.svg"}
              alt=""
              onClick={handleEdit}
            />
            <img
              className="search-icon"
              onClick={handleDelete}
              src={process.env.PUBLIC_URL + "/assets/icons/delete.svg"}
              alt=""
            />
          </div>
        </div>
        <div className="showmember-content">
          <div className="names">
            <h4 className="members-details capitalize"><span>Name:</span> <span>{data.name}</span></h4>
            {/* <h4 className="members-details capitalize">Lastname</h4> */}
          </div>
          <span className="members-details"><span>Email:</span> <span>{data.email}</span></span>
          {/* <span className="members-details capitalize">Password</span> */}
          <div className="members-details capitalize"><span>Role:</span> <span>{data.role || "-"}</span></div>
          <div className="members-details capitalize"><span>Region:</span> <span>{data.region || "-"}</span></div>
          <div className="members-details capitalize"><span>Diocese:</span> <span>{data.diocese || "-"}</span></div>
          <div className="members-details capitalize"><span>Deanery:</span> <span>{data.deanery || "-"}</span></div>
          <div className="members-details capitalize"><span>Parish:</span> <span>{data.parish || "-"}</span></div>
        </div>
      </div>
    </div>
  );
};

export default ShowMember;
