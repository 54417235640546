import React from "react";
import "./WorkAndExperience.scss";
import moment from "moment";
import useYouthSectionStore from "../../../store/youthSectionStore";

const WorkAndExperience = () => {
  const { setPreView, preView } = useYouthSectionStore(state => state)
  const { work } = preView?.data
  return (
    <div className="work-wrapper">
      {work?.sort((a, b) => a.startDate < b.startDate ? 1 : -1)?.map((item, i) => {
        return <div className="work" key={i}>
          <div className="work-names">
            {/* --------Role-------- */}
            <div>
              <div className="role-heading">
                <img
                  className="role"
                  src={`${process.env.PUBLIC_URL}/assets/icons/university.svg`}
                  alt="back"
                />
                <h6>Role</h6>
              </div>
              <input type="text" placeholder="Role Name" value={item.role} readOnly />
            </div>

            {/* ------------Employment Type--------- */}
            <div>
              <h6 className="employment-heading">Employment Type</h6>
              <input type="text" placeholder="employment Type" value={item.employementType} readOnly />
            </div>
          </div>

          <div className="company">
            {/* --------company-------- */}
            <div>
              <h6 className="company-heading">Company Name</h6>
              <input type="text" placeholder="Company Name" value={item.companyName} readOnly />
            </div>

            {/* ------------location--------- */}
            <div>
              <div className="location-heading">
                <img
                  className="location"
                  src={`${process.env.PUBLIC_URL}/assets/icons/location.svg`}
                  alt="back"
                />
                <h6>Locations</h6>
              </div>
              <input type="text" placeholder="Locations" value={item.location} readOnly />
            </div>
          </div>

          <div className="dates">
            <div className="start-date">
              <h6>Start Dates</h6>
              <input type="text" placeholder="Start Date" value={moment(item.startDate).format("DD/MM/YYYY")} readOnly />
            </div>

            <div className="end-date">
              <h6>End Dates</h6>
              <input type="text" placeholder="End Date" value={moment(item.endDate).format("DD/MM/YYYY")} readOnly />
            </div>
          </div>
        </div>
      })}
    </div>
  );
};

export default WorkAndExperience;
