import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import DownloadModal from './Modals/DownloadModal/DownloadModal';
import FiltersModal from "./Modals/FiltersModal/FiltersModal"
import { DebounceInput } from 'react-debounce-input';

const Header = ({ setQuery }) => {

    const [showFilterModal, setShowFilterModal] = useState(false);

    const [showDownloadModal, setShowDownloadModal] = useState(false)

    const handleShowFilterModal = () => setShowFilterModal(true);

    const handleShowDownloadModal = () => setShowDownloadModal(true)

    const handleSearch = (e) => setQuery(e.target.value)

    return (
        <div className="head-wrapper">
            <div className="youth-roles">
                <NavLink to="active-youth"><h4 >Active Youth</h4></NavLink>
                <NavLink to="new-request"><h4 >New Request</h4></NavLink>
                <NavLink to="update-request"><h4 >Update Request</h4></NavLink>
                <NavLink to="inactive-youth"><h4 >Inactive Youth</h4></NavLink>
            </div>
            <div className='actions'>
                <div className="filter-options-wrapper">
                    <div className="download" onClick={handleShowDownloadModal}>
                        <img src={`${process.env.PUBLIC_URL}/assets/icons/download.svg`} alt="Download as" />
                    </div>
                    <div className="filters" onClick={handleShowFilterModal}>
                        <img src={`${process.env.PUBLIC_URL}/assets/icons/filter.svg`} alt="Filters" />
                    </div>
                    <DownloadModal showModal={showDownloadModal} setShowModal={setShowDownloadModal} />
                    <FiltersModal showModal={showFilterModal} setShowModal={setShowFilterModal} />
                </div>
                <div className="search-wrapper">
                    <div className="rectangle-group">
                        <img
                            className="search-icon"
                            src={process.env.PUBLIC_URL + "/assets/icons/search.svg"}
                            alt="Search"
                        />
                        <DebounceInput
                            minLength={2}
                            debounceTimeout={500}
                            className="text-area"
                            placeholder={"Search"}
                            onChange={handleSearch}
                        />
                    </div>
                </div></div>
        </div>
    )
}

export default Header