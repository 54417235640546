import React, { useRef } from 'react'
import useOutsideClickDetect from '../../../../hooks/useOutsideClick'
import "./DownloadModal.scss"

const DownloadModal = ({ showModal, setShowModal }) => {

  // Refs & States
  const downloadRef = useRef(null)
  // Hooks & Effects
  useOutsideClickDetect(downloadRef, setShowModal);

  // Handlers
  const handleDownloadExcel = () => {
    console.log("downloading excel")
    setShowModal(false)
  }

  const handleDownloadPdf = () => {
    console.log("downloading pdf")
    setShowModal(false)
  }

  if (!showModal) return null;
  return (
    <div className="download-wrapper" ref={downloadRef}>
      <ul className="download-options" >
        <li onClick={handleDownloadExcel} className='pointer'>
          <img src={`${process.env.PUBLIC_URL}/assets/icons/excel.svg`} alt="Excel" />
          <span>Excel</span>
        </li>
        <li onClick={handleDownloadPdf} className='pointer'>
          <img src={`${process.env.PUBLIC_URL}/assets/icons/pdf.svg`} alt="PDF" />
          <span>pdf</span>
        </li>
      </ul>
    </div>
  )
}

export default DownloadModal