import React from 'react'

const Tooltip = ({ value }) => {

    return (
        <span className="tooltip pointer">
            <span>{`${value[0]?.regionName} > ${value[0]?.dioceseName} > ${value[0]?.deaneryName} > ${value[0]?.youthCount} youth registered`}</span>
        </span>
    )
}

export default Tooltip