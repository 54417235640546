import React, { Fragment } from "react";
import "./Licenses.scss";
import useYouthSectionStore from "../../../store/youthSectionStore";
import moment from "moment";

const Licenses = () => {
  const { preView } = useYouthSectionStore(state => state)
  const { licenses } = preView?.data
  return (
    <div className="license-wrapper">
      {licenses?.sort((a, b) => a.issueingDate < b.issueingDate ? 1 : -1)?.map((item, i) => {
        return <Fragment key={i}>
          <div className="names">
            <div>
              <div className="names-heading">
                <img
                  className="university"
                  src={`${process.env.PUBLIC_URL}/assets/icons/university.svg`}
                  alt="back"
                />
                <h6>License Name</h6>
              </div>
              <input type="text" placeholder="License Name" value={item.licenseName} readOnly />
            </div>

            <div>
              <h6 className="names-heading">Issuing Organization</h6>
              <input type="text" placeholder="Date" value={item.issuedBy} readOnly />
            </div>

          </div>

          <div className="issue-date">
            <h6>Issue Date</h6>
            <input type="text" placeholder="Date" value={moment(item.issueingDate).format("DD/MM/YYYY")} readOnly />
          </div>
        </Fragment>
      })}

    </div>
  );
};

export default Licenses;
