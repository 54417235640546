import React, { useRef } from "react";
import "./AlbumDetails.scss";
import useGalleryStore from "../../../../store/galleryStore.js";
import useOutsideClickDetect from "../../../../hooks/useOutsideClick.jsx";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const AlbumDetails = ({ setShowModal }) => {
  const { preView, setPreView, setEditableData, deleteEvent, setRefresh, refresh } = useGalleryStore(state => state)
  const navigate = useNavigate()
  const formRef = useRef(null);
  useOutsideClickDetect(formRef, setPreView)
  
  const handleDelete = (data) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#FF3E4D",
      confirmButtonText: 'Delete'
    }).then((result) => {
      if (result.isConfirmed) {
        deleteEvent({ id: data.id }).then((res) => {
          if (res) {
            setRefresh(!refresh)
            setPreView(false)
          }
        }).catch((err) => {
          Swal.fire({
            title: 'Error!',
            text: err.response.data.detail,
            icon: 'error',
            confirmButtonText: 'OK'
          })
        })
      }
    })

    // deleteEvent({ id: data.id }).then((res) => {
    //   if (res) {
    //     setRefresh(!refresh);
    //   }
    // }).catch((err) => {
    //   Swal.fire({
    //     title: 'Error!',
    //     text: err.response.data.detail,
    //     icon: 'error',
    //     confirmButtonText: 'OK'
    //   })
    // })
    // setShowModal(false);
  };

  const handleEdit = (node) => {
    setEditableData({ show: true, data: node })
    navigate("?id=" + node.id)
    setPreView(false)
  }

  const { show, data } = preView

  return (
    <>
      {show && (
        <div className="album-wrapper">
          <div className="album-maindiv" ref={formRef}>
            {/* HEADING */}

            <div className="album-header">
              <span></span>
              <span className="btn-close"><img src={process.env.PUBLIC_URL + "/assets/icons/charm_cross.svg"} alt="" onClick={() => setPreView(false)} /></span>
              <h4 className="album-heading">Album Details</h4>
              <div className="album-header-icons">
                <img
                  onClick={() => handleEdit(data)}
                  className=""
                  src={process.env.PUBLIC_URL + "/assets/icons/edit.svg"}
                  alt=""
                />
                <img
                  onClick={() => handleDelete(data)}
                  className=""
                  src={process.env.PUBLIC_URL + "/assets/icons/delete.svg"}
                  alt=""
                />
              </div>
            </div>

            {/* CONTENT */}
            <div className="album-content">
              <h4 className="album-title">{data.albumName}</h4>
              <h4 className="album-title">{moment(data.eventDate).format("DD/MM/YYYY | hh:mm A")}</h4>
              <p>
                {data.description}
              </p>
              <div className="preview-album-img">
                {data.images.map((img, i) => {
                  return <label className="card-body" htmlFor={i} key={i}>
                    <img src={img} />
                  </label>
                })}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AlbumDetails;
