import React from "react";
import "./Bottom.scss";

const Bottom = () => {
  const date = new Date()
  return (
    <div className="bottom">
      <h6>
        Copyright 2020-{date.getFullYear()} Faithbook. All Rights Reserved by ICYM AGRA REGION.
      </h6>
    </div>
  );
};

export default Bottom;
