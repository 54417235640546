import React from 'react'

const Fallback = () => {
    return (
        <div className='fallback'>
            <p>Press on any tab to filter the users</p>
        </div>
    )
}

export default Fallback