import React, { useState } from "react";
import Header from "./Header";
import Table from "./Table";
import "./Contact.scss";

const Contact = () => {

  const [query, setQuery] = useState('')

  return (
    <div className="wrapper">
      <div className="contact-main-div">
        <Header setQuery={setQuery} />
        <div className="contact-data-list">
          <Table query={query} />
        </div>
      </div>
    </div>
  );
};

export default Contact;
