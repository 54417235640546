import { useEffect } from "react";
import useListingStore from "../store/listingStore";

function useOutsideClickDetect(ref, setState) {
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                setState(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    // eslint-disable-next-line 
    }, [ref]);
}


export default useOutsideClickDetect;