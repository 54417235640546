import React, { useEffect, useRef, useState } from "react";
import { api } from "../../utils/api";
import useListingStore from "../../store/listingStore";
import useUploadFileToS3 from "../../hooks/useUploadFileToS3";
import { constants } from "../../utils/constants";

const Header = ({ handleShowModal }) => {

  const [file, setFile] = useState([]);
  const handleFileChange = (e) => {
    const fileRes = e.target.files;
    if (fileRes.length) {
      for (let i = 0; i < fileRes.length; i++) {
        const element = fileRes[i];
        file.push(element)
        setFile([...file])
      }
    }
  };
  const { feedFile, listingTrigger, setListingTrigger } = useListingStore((state) => state);
  const filePath = `${constants.AWS_S3.listing}`
  const { data } = useUploadFileToS3(file, filePath, feedFile)

  const handleSelectFile = () => {
    if (inputRef && inputRef.current) {
      inputRef.current.click()
    }
  }

  const inputRef = useRef(null)
  const handleDownloadFile = () => window.location.href = api.listing.downloadFile + api.listing.fileName;

  useEffect(() => {
    if (data && data.result) {
      setListingTrigger(!listingTrigger);
      setFile([])
    };
  }, [data])

  return (
    <div className="head-wrapper">
      <div className="listing-roles">
        <h3>Listing</h3>
      </div>
      <div className="actions">
        <div className="filter-options-wrapper">
          <div className="download" onClick={handleDownloadFile}>
            <img
              src={`${process.env.PUBLIC_URL}/assets/icons/download.svg`}
              alt="Download"
            />
          </div>
          <div className="filters" onClick={handleSelectFile}>
            <input ref={inputRef} style={{ display: "none" }} type="file" multiple onChange={handleFileChange} value={""} />
            <img
              src={`${process.env.PUBLIC_URL}/assets/icons/upload.svg`}
              alt="Upload"
            />
          </div>
        </div>
        <div className="search-wrapper">
          {/* <div className="rectangle-group">
          <img
            className="search-icon"
            src={process.env.PUBLIC_URL + "/assets/icons/search.svg"}
            alt=""
          />
          <input type="text" className="text-area" placeholder="Search" value={""} />
        </div> */}

          <button className="add-btn pointer" onClick={handleShowModal}>
            + Add new
          </button>
        </div>
      </div>
    </div>
  );
};

export default Header;
