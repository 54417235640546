import React from 'react'
import { Route, Routes } from 'react-router'
import { Navigate } from 'react-router-dom'
import LoginPage from '../pages/LoginPage'
import RequestPasswordPage from '../pages/RequestPasswordPage'


const OpenRouter = () => {
    return (
        <div className="layout">
            <main>
                <Routes>
                    <Route path='/login' element={<LoginPage />} />
                    <Route path='/request-password' element={<RequestPasswordPage />} />
                    <Route path='/*' element={<Navigate to="/login" />} />
                </Routes>
            </main>
        </div>
    )
}

export default OpenRouter