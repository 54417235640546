import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./MemberDetails.scss";
import Header from "./Header";
import PersonalDetails from "./Forms/PersonalDetails";
import SpiritualDetails from "./Forms/SpiritualDetails";
import FamilyDetails from "./Forms/FamilyDetails";
import Education from "./Forms/Education";
import WorkAndExperience from "./Forms/WorkAndExperience";
import Licenses from "./Forms/Licenses";
import ContactDetails from "./Forms/ContactDetails";
import useYouthSectionStore from "../../store/youthSectionStore";

const MemberDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { setPreView } = useYouthSectionStore(state => state)
  useEffect(() => {
    navigate("personal-details");
  }, []);

  return (
    <div className="wrapper">
      <div className="member-details-maindiv">
        {/* -------------BACK-ARROW---------------- */}
        <span className="back-arrow">
          <img
            className={"pointer"}
            src={`${process.env.PUBLIC_URL}/assets/icons/back-arrow.svg`}
            alt="back"
            onClick={() => { navigate("/youth-section"); setPreView({ show: false, data: null }) }}
          />
          <h4>Member Details</h4>
        </span>

        {/* -----------------HEADER----------------- */}
        <div className="main-content">
          <Header />
          {location.pathname.includes("/personal-details") && <PersonalDetails />}
          {location.pathname.includes("/spiritual-details") && <SpiritualDetails />}
          {location.pathname.includes("/family-details") && <FamilyDetails />}
          {location.pathname.includes("/education") && <Education />}
          {location.pathname.includes("/work-experience") && <WorkAndExperience />}
          {location.pathname.includes("/licenses") && <Licenses />}
          {location.pathname.includes("/contact") && <ContactDetails />}
        </div>
      </div>
    </div>
  );
};

export default MemberDetails;
