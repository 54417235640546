import React, { useEffect, useRef, useState } from "react";
import useOutsideClickDetect from "../../../../hooks/useOutsideClick";
import "./AddNew.scss";
import useListingStore from "../../../../store/listingStore";
import Swal from "sweetalert2";

const AddNew = ({ showModal, setShowModal, data }) => {

  const initialState = {
    parish: null,
    region: null,
    diocese: null,
    deanery: null
  };

  const initialDropDownValues = {
    region: [],
    diocese: [],
    deanery: []
  }

  const formFor = {
    1: "Region",
    2: "Diocese",
    3: "Deanery",
    4: "Parish"
  }

  const showInputs = {
    "selectOne": [2, 3, 4],
    "selectTwo": [3, 4],
    "selectThree": [4],
  }

  // Refs & States
  const formRef = useRef(null);

  const { setListingTrigger, listingTrigger, addListing, updateListing, nodeInfo, setNodeInfo } = useListingStore((store) => store)
  const [dropDownValues, setDropDownValues] = useState(initialDropDownValues)
  const [values, setValues] = useState(initialState);
  const [labelColor, setLabelColor] = useState(1);
  useOutsideClickDetect(formRef, setShowModal);
  console.log(values)
  console.log(nodeInfo)
  useEffect(() => {
    if (nodeInfo.roleType) {
      Object.entries(formFor).map(([key, value], i) => {
        if (value.toLowerCase() === nodeInfo.roleType) setLabelColor(+key)
      })
      setValues(nodeInfo)
    }
  }, [nodeInfo.roleType])

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (nodeInfo.roleType) {
      updateListing({ ...values }).then((data) => {
        if (data) {
          Swal.fire({
            title: 'Success!',
            text: "Record added successfully",
            icon: 'success',
            confirmButtonText: 'OK'
          })
          setValues(initialState)
          setShowModal(false);
          setListingTrigger(!listingTrigger)
        }
      }).catch((err) => {
        if (err) {
          Swal.fire({
            title: 'Error!',
            text: err.response.data.detail,
            icon: 'error',
            confirmButtonText: 'OK'
          })
        }
      })
    }
    addListing({ ...values }).then((data) => {
      if (data) {
        Swal.fire({
          title: 'Success!',
          text: "Record added successfully",
          icon: 'success',
          confirmButtonText: 'OK'
        })
        setValues(initialState)
        setShowModal(false);
        setListingTrigger(!listingTrigger)
      }
    }).catch((err) => {
      if (err) {
        Swal.fire({
          title: 'Error!',
          text: err.response.data.detail,
          icon: 'error',
          confirmButtonText: 'OK'
        })
      }
    })
  };

  const handleLabelColor = (button) => {
    if (nodeInfo.roleType) return
    setLabelColor(button);
  };

  useEffect(() => {
    setValues(initialState)
    return () => {
      setValues(initialState)
    }
  }, [labelColor])

  useEffect(() => {
    if (data)
      setDropDownValues({
        region: data && Object.keys(data) || [],
        diocese: data && data[values.region || nodeInfo.region] && Object.keys(data[values.region || nodeInfo.region]) || [],
        deanery: data && data[values.region || nodeInfo.region] && data[values.region || nodeInfo.region][values.diocese || nodeInfo.diocese] && Object.keys(data[values.region || nodeInfo.region][values.diocese || nodeInfo.diocese]) || []
      })
  }, [data, values]);

  if (!showModal) return null;
  if (data && dropDownValues)
    return (
      <div className="addlist-wrapper">
        <form className="addlist-form" ref={formRef} onSubmit={handleSubmit}>
          {/* ------------------HEADERS--------------- */}
          <div className="addlist-header">
            <span></span>
            <span className="btn-close"><img src={process.env.PUBLIC_URL + "/assets/icons/charm_cross.svg"} alt="" onClick={() => setShowModal(false)} /></span>
            <h4 className="addlist-heading capitalize">+{nodeInfo.roleType ? "Update " + nodeInfo.roleType : "Add New"}</h4>
            <button className="save" type="submit">{nodeInfo.roleType ? "Update" : "Save"}</button>
          </div>

          <div className="addlist-content">
            <div className="select-audience-type">
              <label
                htmlFor="1"
                onClick={() => handleLabelColor(1)}
                style={{
                  background: labelColor === 1 ? "#194459" : "white",
                  color: labelColor === 1 ? "white" : "#5b686e",
                }}
              >
                <input className="audience-input" type="radio" id="1" />
                <span>Region</span>
              </label>
              <label
                htmlFor="2"
                onClick={() => handleLabelColor(2)}
                style={{
                  background: labelColor === 2 ? "#194459" : "white",
                  color: labelColor === 2 ? "white" : "#5b686e",
                }}
              >
                <input className="audience-input" type="radio" id="2" />
                <span>Diocese</span>
              </label>
              <label
                htmlFor="3"
                onClick={() => handleLabelColor(3)}
                style={{
                  background: labelColor === 3 ? "#194459" : "white",
                  color: labelColor === 3 ? "white" : "#5b686e",
                }}
              >
                <input className="audience-input" type="radio" id="3" />
                <span>Zone</span>
              </label>
              <label
                htmlFor="4"
                onClick={() => handleLabelColor(4)}
                style={{
                  background: labelColor === 4 ? "#194459" : "white",
                  color: labelColor === 4 ? "white" : "#5b686e",
                }}
              >
                <input className="audience-input" type="radio" id="4" />
                <span>Parish</span>
              </label>
            </div>

            <div className="addlist-inputs">
              {showInputs["selectOne"].includes(labelColor) && <div className="select-wrapper">
                <select required={showInputs["selectOne"].includes(labelColor)} name="region" value={values.region} onChange={handleChange}>
                  <option value="">Select Region</option>
                  {dropDownValues?.region?.map((name, i) => {
                    if (name === "null") return null
                    return <option key={i} value={name}>{name}</option>
                  })}
                </select>
                <span>
                  <img src={process.env.PUBLIC_URL + "/assets/icons/drop-down.svg"} alt="" />
                </span>
              </div>}
              {showInputs["selectTwo"].includes(labelColor) && <div className="select-wrapper">
                <select required={showInputs["selectTwo"].includes(labelColor)} name="diocese" value={values.diocese} onChange={handleChange}>
                  <option value="">Select Diocese</option>
                  {dropDownValues?.diocese?.map((name, i) => {
                    if (name === "null") return null
                    return <option key={i} value={name}>{name}</option>
                  })}
                </select>
                <span>
                  <img src={process.env.PUBLIC_URL + "/assets/icons/drop-down.svg"} alt="" />
                </span>
              </div>}
              {showInputs["selectThree"].includes(labelColor) && <div className="select-wrapper">
                <select required={showInputs["selectThree"].includes(labelColor)} name="deanery" value={values.deanery} onChange={handleChange}>
                  <option value="">Select Zone</option>
                  {dropDownValues?.deanery?.map((name, i) => {
                    if (name === "null") return null
                    return <option key={i} value={name}>{name}</option>
                  })}
                </select>
                <span>
                  <img src={process.env.PUBLIC_URL + "/assets/icons/drop-down.svg"} alt="" />
                </span>
              </div>}

              <input
                type="text"
                placeholder={formFor[labelColor] + " Name"}
                name={formFor[labelColor].toLowerCase()}
                value={values[formFor[labelColor].toLowerCase()]}
                onChange={handleChange}
                required={true}
              />
            </div>
          </div>
        </form>
      </div>
    );
};

export default AddNew;
