import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import useSearch from '../../../hooks/useSearch'
import useInfiniteScroll from '../../../hooks/useInfiniteScroll'
import { BASE_URL, api } from '../../../utils/api'
import useYouthSectionStore from '../../../store/youthSectionStore'
import Swal from 'sweetalert2'

const InctiveYouth = ({ query }) => {
    const { activateYouth, setRefresh, refresh, setPreView } = useYouthSectionStore(state => state)
    const navigate = useNavigate()
    const location = useLocation()

    const [page, setPage] = useState(1);
    const [size, setSize] = useState(10);
    const { data, lastElement, loading, error } = useInfiniteScroll(
        BASE_URL + api.user.getAll,
        {
            page: page,
            size: size,
            role: 'youth',
            status: 'inactive'
        },
        setPage,
        "results", refresh
    );

    const searchData = useSearch(
        BASE_URL + api.user.getAll,
        {
            page: 1,
            size: 100,
            role: 'youth',
            status: 'inactive',
            name: query
        },
        setPage,
        "results"
    );

    const handleShare = (user) => {
        setPreView({
            show: true,
            data: user
        })
        navigate("/youth-section/preview")
    }

    const handleReactivate = (id) => {
        activateYouth({ id }).then((data) => {
            console.log(data)
            if (data) setRefresh(!refresh)
        }).catch((err) => {
            Swal.fire({
                title: 'Error!',
                text: err.response.data.detail,
                icon: 'error',
                confirmButtonText: 'OK'
            })
        })
    }

    return (
        <>
            <table>
                <thead>
                    <tr className='head'>
                        <th>Name</th>
                        <th>Region</th>
                        <th>Diocese</th>
                        <th>Zone</th>
                        <th>Parish</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {(query ? searchData?.data : data)?.map((user, index) => {
                        if (data.length === index + 1) {
                            return <tr ref={lastElement} key={index}>
                                <td>{user.name}</td>
                                <td>{user.region}</td>
                                <td>{user.diocese}</td>
                                <td>{user.deanery || "-"}</td>
                                <td>{user.parish}</td>
                                <td className='input-data-icon'>
                                    <img className='pointer' alt="Share" src={`${process.env.PUBLIC_URL}/assets/icons/share.svg`} onClick={() => handleShare(user)}></img>
                                    <img className='pointer' alt="Reactivate" src={`${process.env.PUBLIC_URL}/assets/icons/reactivate.svg`} onClick={() => handleReactivate(user.id)}></img>
                                </td>
                            </tr>
                        }
                        return <tr key={index}>
                            <td>{user.name}</td>
                            <td>{user.region}</td>
                            <td>{user.diocese}</td>
                            <td>{user.deanery || "-"}</td>
                            <td>{user.parish}</td>
                            <td className='input-data-icon'>
                                <img className='pointer' alt="Share" src={`${process.env.PUBLIC_URL}/assets/icons/share.svg`} onClick={() => handleShare(user)}></img>
                                <img className='pointer' alt="Reactivate" src={`${process.env.PUBLIC_URL}/assets/icons/reactivate.svg`} onClick={() => handleReactivate(user.id)}></img>
                            </td>
                        </tr>
                    })}
                </tbody>
            </table>
            {loading && <div className="loading"><span>Loading</span></div>}
            {error && <div className="error"><span>Unable to load data</span></div>}
        </>

    )
}

export default InctiveYouth