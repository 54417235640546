import axios from 'axios'
import { useEffect, useState } from 'react'

const useFetch = (url, bool) => {

    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)

    const getData = async (url) => {
        const res = await axios.get(url);
        const data = await res.data
        return data
    }
    
    useEffect(() => {
        setError(false)
        setLoading(true)
        getData(url).then((datas) => {
            setData(datas)
            setLoading(false)
            setError(false)
        }).catch((err) => {
            if (err) setError(true)
        })
    }, [url, bool])


    return { data, loading, error }
}

export default useFetch