import React from "react";
import { DebounceInput } from "react-debounce-input";

const Header = ({ setQuery }) => {

  const handleSearch = (e) => setQuery(e.target.value)
  return (
    <div className="head-wrapper">
      <div className="head-roles">
        <h3>Contact us</h3>
      </div>
      <div className="head-search-wrapper">
        <div className="rectangle-group">
          <img
            className="search-icon"
            src={process.env.PUBLIC_URL + "/assets/icons/search.svg"}
            alt=""
          />
          <DebounceInput
            minLength={2}
            debounceTimeout={500}
            className="text-area"
            placeholder={"Search"}
            onChange={handleSearch}
          />
        </div>
      </div>
    </div>
  );
};

export default Header;
