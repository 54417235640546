import React, { useState } from "react";
import useInfiniteScroll from "../../hooks/useInfiniteScroll";
import useSearch from "../../hooks/useSearch";
import { BASE_URL, api } from "../../utils/api";
import useCampusStore from "../../store/campusStore";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import useAuthStore from "../../store/authStore";
import { constants } from "../../utils/constants";

const Table = ({ query }) => {
  const { user } = useAuthStore(state => state)
  const { setPreView, setEditableData, deleteEvent, refresh, setRefresh } = useCampusStore(state => state)
  const navigate = useNavigate()
  const headers = ["College Name", "University Name", "Location", "Action"];

  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const { data, lastElement, loading, error } = useInfiniteScroll(
    BASE_URL + api.campus.getAll,
    {
      page: page,
      size: size
    },
    setPage,
    "results", refresh
  );

  const searchData = useSearch(
    BASE_URL + api.campus.getAll,
    {
      page: 1,
      size: 100,
      collegeName: query
    },
    setPage,
    "results"
  );

  const handleShare = (node) => {
    setPreView({
      show: true,
      data: node
    })
  };

  const handleEdit = (node) => {
    setEditableData({
      show: true,
      data: node
    })
    navigate("?id=" + node.id)
  }

  const handleDelete = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#FF3E4D",
      confirmButtonText: 'Delete'
    }).then((result) => {
      if (result.isConfirmed) {
        deleteEvent({ id: id }).then((res) => {
          if (res) {
            setRefresh(!refresh)
          }
        }).catch((err) => {
          Swal.fire({
            title: 'Error!',
            text: err.response.data.detail,
            icon: 'error',
            confirmButtonText: 'OK'
          })
        })
      }
    })
  }

  return (
    <table>
      <thead>
        <tr className="head">
          {headers.map((th, i) => {
            return <th key={i}>{th}</th>;
          })}
        </tr>
      </thead>
      <tbody>
        {(query ? searchData?.data : data)?.map((campus, index) => {
          if (data.length === index + 1) {
            return <tr key={index} ref={lastElement}>
              <td>{campus.collegeName || "-"}</td>
              <td>{campus.universiyName || "-"}</td>
              <td>{campus.location || "-"}</td>
              <td className="input-data-icon">
                <img
                  className="pointer"
                  alt="View"
                  src={`${process.env.PUBLIC_URL}/assets/icons/share.svg`}
                  onClick={() => handleShare(campus)}
                ></img>
                {user.role === constants.role.ADMIN && <>
                  <img
                    className="pointer"
                    alt="Edit"
                    src={`${process.env.PUBLIC_URL}/assets/icons/edit.svg`}
                    onClick={() => handleEdit(campus)}
                  ></img>
                  <img
                    className="pointer"
                    alt="Delete"
                    src={`${process.env.PUBLIC_URL}/assets/icons/delete.svg`}
                    onClick={() => handleDelete(campus.id)}
                  ></img>
                </>}
              </td>
            </tr>
          }
          return (
            <tr key={index}>
              <td>{campus.collegeName || "-"}</td>
              <td>{campus.universiyName || "-"}</td>
              <td>{campus.location || "-"}</td>
              <td className="input-data-icon">
                <img
                  className="pointer"
                  alt="View"
                  src={`${process.env.PUBLIC_URL}/assets/icons/share.svg`}
                  onClick={handleShare}
                ></img>
                <img
                  className="pointer"
                  alt="Edit"
                  src={`${process.env.PUBLIC_URL}/assets/icons/edit.svg`}
                  onClick={() => handleEdit(campus)}
                ></img>
                <img
                  className="pointer"
                  alt="Delete"
                  src={`${process.env.PUBLIC_URL}/assets/icons/delete.svg`}
                  onClick={() => handleDelete(campus.id)}
                ></img>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default Table;
