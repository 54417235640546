import Cookies from "js-cookie";
import { constants } from "../constants";

class Auth {
  static authenticateUser(token) {
    const expirationTime = new Date();
    expirationTime.setTime(expirationTime.getTime() + 10 * 60 * 60 * 1000);
    Cookies.set(constants.auth.token, token, { expires: expirationTime });
  }
  static getToken() {
    return Cookies.get(constants.auth.token); // "t0k3n"
  }
}

export default Auth;
