import React, { useState } from "react";
import FiltersModal from "./Modals/FiltersModal/FiltersModal";
import useAuthStore from "../../store/authStore";
import { DebounceInput } from "react-debounce-input";
import useCampusStore from "../../store/campusStore";
import { useNavigate } from "react-router-dom";
import { constants } from "../../utils/constants";

const Header = ({ setQuery }) => {
  const navigate = useNavigate()
  const { user } = useAuthStore(state => state)
  const { setEditableData } = useCampusStore(state => state)

  const [showFilterModal, setShowFilterModal] = useState(false);

  const handleSearch = (e) => setQuery(e.target.value)

  const handleShowFilterModal = () => {
    setShowFilterModal(true);
  };

  const handleAdd = () => {
    setEditableData({
      show: true,
      data: null
    })
    navigate("/campus-connect")
  }

  return (
    <div className="head-wrapper">
      <div className="campus-roles">
        <h4>Campus connect</h4>
      </div>
      <div className="actions">
        <div className="campus-filter-options-wrapper">
          <div className="filters" onClick={handleShowFilterModal}>
            <img
              src={`${process.env.PUBLIC_URL}/assets/icons/filter.svg`}
              alt="Filters"
            />
          </div>

          <FiltersModal
            showModal={showFilterModal}
            setShowModal={setShowFilterModal}
          />
        </div>
        <div className="search-wrapper">
          <div className="rectangle-group">
            <img
              className="search-icon"
              src={process.env.PUBLIC_URL + "/assets/icons/search.svg"}
              alt=""
            />
            {/* <input type="text" className="text-area" placeholder="Search" /> */}
            <DebounceInput
              minLength={2}
              debounceTimeout={500}
              className="text-area"
              placeholder={"Search"}
              onChange={handleSearch}
            />
          </div>
          {user.role === constants.role.ADMIN && <button className="add-btn pointer" onClick={handleAdd}>
            +Add new
          </button>}
        </div>
      </div>
    </div>
  );
};

export default Header;
