import React, { useEffect, useRef, useState } from "react";
import useOutsideClickDetect from "../../../../hooks/useOutsideClick";
import "./CollegeDetails.scss";
import useCampusStore from "../../../../store/campusStore";
import { constants } from "../../../../utils/constants";
import useUploadFileToS3 from "../../../../hooks/useUploadFileToS3";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const CollegeDetails = () => {
  // Refs & States
  const { editable, setEditableData, refresh, setRefresh, addEvent, updateEvent } = useCampusStore(state => state)
  const formRef = useRef(null);

  const locationHook = useLocation()
  const queryParams = new URLSearchParams(locationHook.search)
  const navigate = useNavigate()

  const initialState = {
    collegeName: "",
    universiyName: "",
    address: "",
    location: "",
    email: "",
    website: "",
    description: "",
    images: []
  };
  const [values, setValues] = useState(initialState);
  const { collegeName, universiyName, address, location, email, website, description } = values;

  useOutsideClickDetect(formRef, setEditableData);
  console.log(editable)


  const filePath = `${constants.AWS_S3.campus}`
  const [files, setFiles] = useState([])
  const { data } = useUploadFileToS3(files, filePath)

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   setEditableData({ show: false, data: null })
  // };
  const handleSubmit = (e) => {
    e.preventDefault();
    const body = { ...values }
    const { updatedAt, createdAt, id, ...rest } = body
    const params = { id: id }
    if (queryParams.has("id")) {
      updateEvent(params, { ...rest, images: [...editable?.data?.images, ...data] }).then((res) => {
        if (res) {
          Swal.fire({
            title: 'Success!',
            text: 'Record updated successfully',
            icon: 'success',
            confirmButtonText: 'OK'
          })
          setRefresh(!refresh)
          setEditableData({
            show: false,
            data: res.data.result
          })
          navigate("/campus-connect")
        }
      }).catch((err) => {
        Swal.fire({
          title: 'Error!',
          text: err.response.data.detail,
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
    }
    else {
      addEvent({ ...body, images: [...data] }).then((res) => {
        if (res) {
          Swal.fire({
            title: 'Success!',
            text: 'Record added successfully',
            icon: 'success',
            confirmButtonText: 'OK'
          })
          setRefresh(!refresh)
          setEditableData({
            show: false,
            data: null,
          });
        }
      }).catch((err) => {
        Swal.fire({
          title: 'Error!',
          text: err.response.data.detail,
          icon: 'error',
          confirmButtonText: 'OK'
        })
      });
    }
  };

  useEffect(() => {
    if (editable?.data) setValues({ ...editable?.data });
    else setValues(initialState);
  }, [editable.data])

  const inputRef = useRef(null)
  const handleSelectFile = () => inputRef && inputRef?.current && inputRef?.current?.click()
  const handleFileChange = (e) => {
    const fileRes = e.target.files;
    const file = []
    if (fileRes.length) {
      for (let i = 0; i < fileRes.length; i++) {
        const element = fileRes[i];
        if (element.name) { file.push(element); files.push(element) }
        setValues({ ...values, images: [...values?.images, ...file] })
      }
      setFiles([...files])
    }
  }

  return (
    <>
      {editable?.show && (
        <div className="campus-wrapper">
          <form className="campus-form" ref={formRef} onSubmit={handleSubmit}>
            <div className="campus-header">
              <span></span>
              <span className="btn-close"><img src={process.env.PUBLIC_URL + "/assets/icons/charm_cross.svg"} alt="" onClick={() => setEditableData(false)} /></span>
              {/* <span></span> */}
              <h4 className="campus-heading">College details</h4>
              <button className="save">{queryParams.has("id") ? "Update" : "Save"}</button>
            </div>

            <div className="campus-content">
              {/* -------------------TYPE OF AUDIENCE------------------ */}

              {/* NEW NOTIFICATIONS INPUTS */}

              <div className="campus-inputs">
                <input
                  type="text"
                  placeholder="College Name"
                  name="collegeName"
                  value={collegeName}
                  onChange={handleChange}
                />
                <input
                  type="text"
                  placeholder="University Name"
                  name="universiyName"
                  value={universiyName}
                  onChange={handleChange}
                />
                <input
                  type="text"
                  placeholder="Address"
                  name="address"
                  value={address}
                  onChange={handleChange}
                />
                <input
                  type="text"
                  placeholder="Location"
                  name="location"
                  value={location}
                  onChange={handleChange}
                />
                <div className="links">
                  <input
                    className="links-input"
                    type="email"
                    placeholder="Email Id"
                    name="email"
                    value={email}
                    onChange={handleChange}
                  />
                  <input
                    className="links-input"
                    type="text"
                    placeholder="Website link"
                    name="website"
                    value={website}
                    onChange={handleChange}
                  />
                </div>

                <textarea
                  type="text"
                  placeholder="Description"
                  name="description"
                  value={description}
                  onChange={handleChange}
                />
                <div className="img-wrapper">
                  <label className="card-body" htmlFor="0" onClick={handleSelectFile}>
                    <input className="add-input" style={{ display: "none" }} ref={inputRef} multiple type="file" onChange={handleFileChange} />
                    <img
                      className="add-img"
                      src={`${process.env.PUBLIC_URL}/assets/icons/img-add.svg`}
                      alt="Add"
                    />
                    <span>+Add Image</span>
                  </label>

                  {values?.images?.map((img, i) => {
                    if (img.name) {
                      return <label className="card-body2" htmlFor={i} key={i}>
                        <img
                          className=""
                          src={URL.createObjectURL(img)}
                          alt="Add"
                        />
                      </label>
                    }
                    return <label className="card-body2" htmlFor={i} key={i}>
                      <img
                        className=""
                        src={img}
                        alt="Add"
                      />
                    </label>
                  })}

                </div>
              </div>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default CollegeDetails;
