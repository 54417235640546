import React, { useRef } from 'react'
import "./EditModal.scss"
import useOutsideClickDetect from '../../../../hooks/useOutsideClick'

const EditModal = ({ setState, handleUpdate, handleDelete }) => {

  const alterOptionsRef = useRef(null)
  useOutsideClickDetect(alterOptionsRef, setState)

  return (
    <div className="alterOptions" ref={alterOptionsRef}>
      <ul>
        {/* <li onClick={handleUpdate}>
          <span><img src={process.env.PUBLIC_URL + "/assets/icons/edit.svg"} alt="Edit" />
          </span><span>Edit</span>
        </li> */}
        <li onClick={handleDelete}>
          <span><img src={process.env.PUBLIC_URL + "/assets/icons/delete.svg"} alt="Delete" /></span>
          <span>Delete</span>
        </li>
      </ul>
    </div>
  )
}

export default EditModal