import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import useInfiniteScroll from "../../../hooks/useInfiniteScroll";
import { BASE_URL, api } from "../../../utils/api";
import useSearch from "../../../hooks/useSearch";
import moment from "moment";
import useNewsStore from "../../../store/newsStore";

const News = ({ query }) => {
    const { setPreView, refresh } = useNewsStore(store => store)
    const headers = ["Title", "Audience", "Date", "Action"];
    const navigate = useNavigate();
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(10);
    const { data, lastElement, loading, error } = useInfiniteScroll(
        BASE_URL + api.news.getAll,
        {
            page: page,
            size: size,
            is_news: true,
        },
        setPage,
        "results", refresh  
    );

    const searchData = useSearch(
        BASE_URL + api.news.getAll,
        {
            page: 1,
            size: 100,
            is_news: true,
            title: query
        },
        setPage,
        "results"
    );

    const handleUpdate = (id) => {
        navigate(`?id=${id}`);
    };

    const handleShare = (node) => {
        setPreView({
            show: true,
            data: node
        })
    };

    return (
        <>
            <table>
                <thead>
                    <tr className="head">
                        {headers.map((th, i) => {
                            return <th key={i}>{th}</th>
                        })}
                    </tr>
                </thead>
                <tbody>
                    {(query ? searchData?.data : data)?.map((item, index) => {
                        if (data.length === index + 1) {
                            return <tr key={index} ref={lastElement}>
                                <td>{item.title}</td>
                                <td className="capitalize">{item.audience || "-"}</td>
                                <td>{moment(item.createdAt).format("DD/MM/YYYY") || "-"}</td>
                                <td className="input-data-icon">
                                    <img
                                        className="pointer"
                                        alt="Share"
                                        src={`${process.env.PUBLIC_URL}/assets/icons/share.svg`}
                                        onClick={()=>handleShare(item)}
                                    ></img>
                                </td>
                            </tr>
                        }
                        return (
                            <tr key={index}>
                                <td>{item.title}</td>
                                <td className="capitalize">{item.audience || "-"}</td>
                                <td>{moment(item.createdAt).format("DD/MM/YYYY") || "-"}</td>
                                <td className="input-data-icon">
                                    <img
                                        className="pointer"
                                        alt="Share"
                                        src={`${process.env.PUBLIC_URL}/assets/icons/share.svg`}
                                        onClick={()=>handleShare(item)}
                                    ></img>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            {loading && <div className="loading"><span>Loading</span></div>}
            {error && <div className="error"><span>Unable to load data</span></div>}
        </>
    );
};

export default News;
