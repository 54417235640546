import React, { useRef } from "react";
import "./ShowNews.scss";
import useNewsStore from "../../../../store/newsStore";
import useOutsideClickDetect from "../../../../hooks/useOutsideClick";

const Shownews = () => {

  const formRef = useRef(null)
  const { preView, setPreView } = useNewsStore(store => store)
  useOutsideClickDetect(formRef, setPreView);

  const {
    title,
    body,
    author,
    audience,
    audienceType,
    images,
    is_news,
    is_announcement
  } = preView?.data

  return (
    <div className="news-wrapper">
      <div className="shownews-maindiv" ref={formRef}>
        {/* HEADING */}
        <div className="show-newscontent-header">
          <span></span>
          <span className="btn-close"><img src={process.env.PUBLIC_URL + "/assets/icons/charm_cross.svg"} alt="" onClick={() => setPreView(false)} /></span>
          <h4 className="show-newscontent-heading">{is_news ? "News" : "Announcement"}</h4>
          <span></span>
        </div>

        {/* CONTENT */}
        <div className="shownews-content">
          <img
            className="news-topimage"
            src={images && images[0] ? images[0] : `${process.env.PUBLIC_URL}/assets/images/announcement.svg`}
            alt=""
          />

          <h4 className="shownews-title">{title}</h4>
          <p>
            {body}
          </p>
          <h5>Audience</h5>
          <h4 className="shownews-regionname">{audience || "NA"}</h4>
        </div>
      </div>
    </div>
  );
};

export default Shownews;
