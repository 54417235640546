import React from "react";
import { DebounceInput } from "react-debounce-input";
import useNotificationStore from "../../store/notificationStore";
import { useNavigate } from "react-router-dom";


const Header = ({ setQuery }) => {
  const { setEditableData } = useNotificationStore(store => store)
  const navigate = useNavigate()
  const handleSearch = (e) => setQuery(e.target.value)

  const handleAdd = () => {
    setEditableData({
      show: true,
      data: null
    })
    navigate("/notification")
  }

  return (
    <div className="head-wrapper">
      <div className="notifications-roles">
        <h3>Notifications</h3>
      </div>
      <div className="search-wrapper">
        <div className="rectangle-group">
          <img
            className="search-icon"
            src={process.env.PUBLIC_URL + "/assets/icons/search.svg"}
            alt=""
          />
          {/* <input type="text" className="text-area" placeholder="Search" /> */}
          <DebounceInput
            minLength={2}
            debounceTimeout={500}
            className="text-area"
            placeholder={"Search"}
            onChange={handleSearch}
          />
        </div>

        <button className="add-btn pointer" onClick={handleAdd}>
          +Create
        </button>
      </div>
    </div>
  );
};

export default Header;
