import React from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { DebounceInput } from 'react-debounce-input';
import useAuthStore from '../../store/authStore';
import { constants } from '../../utils/constants';
import useRoleManagementStore from '../../store/roleManagementStore';

const Header = ({ setQuery }) => {
    const navigate = useNavigate()
    const { user } = useAuthStore((state) => state);
    const { setEditableData } = useRoleManagementStore(state => state)

    const handleSearch = (e) => setQuery(e.target.value)

    const handleAdd = () => {
        setEditableData({
            show: true,
            data: null
        })
        navigate("/role-management/all")
    }

    return (
        <div className="head-wrapper">
            <div className="rolemanagement-roles">
                {user.role === constants.role.ADMIN && <>
                    <NavLink to="all"><h4 >Role Management (All)</h4></NavLink>
                    <NavLink to="admin"><h4 >Admin</h4></NavLink>
                    <NavLink to="region"><h4 >Region</h4></NavLink>
                </>}
                {(user.role === constants.role.ADMIN || user.role === constants.role.REGION) && <>
                    <NavLink to="diocese"><h4 >Diocese</h4></NavLink>
                </>}
            </div>
            <div className="search-wrapper">
                <div className="rectangle-group">
                    <img
                        className="search-icon"
                        src={process.env.PUBLIC_URL + "/assets/icons/search.svg"}
                        alt="Search"
                    />
                    <DebounceInput
                        minLength={2}
                        debounceTimeout={500}
                        className="text-area"
                        placeholder={"Search"}
                        onChange={handleSearch}
                    />
                </div>

                {user.role !== constants.role.PARISH && <button className="add-btn pointer" onClick={handleAdd}>
                    +Add new
                </button>}
            </div>
        </div>
    )
}

export default Header