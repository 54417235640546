import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import useOutsideClickDetect from "../../../../hooks/useOutsideClick";
import "./AddModal.scss";
import useRoleManagementStore from "../../../../store/roleManagementStore";
import { constants } from "../../../../utils/constants";
import useFetch from "../../../../hooks/useFetch";
import { BASE_URL, api } from "../../../../utils/api";
import useAuthStore from "../../../../store/authStore";
import Swal from "sweetalert2";

const AddModal = () => {
  const { editable, setEditableData, refresh, setRefresh, addEvent, updateEvent } = useRoleManagementStore(store => store)
  const { user } = useAuthStore(store => store)
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  // Refs & States
  const formRef = useRef(null);

  const initialState = {
    name: "",
    email: "",
    password: "",
    role: "",
    region: "",
    diocese: "",
    deanery: "",
    parish: ""
  };
  const [values, setValues] = useState(initialState);
  const { name, email, password, role, region, diocese, deanery, parish } = values;

  // Hooks & Effects
  useOutsideClickDetect(formRef, setEditableData);

  // Handlers
  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const showSelection = {
    region: ['region', 'diocese', 'deanery', 'parish'],
    diocese: ['diocese', 'deanery', 'parish'],
    deanery: ['deanery', 'parish'],
    parish: ['parish']
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const params = { id: id }
    if (id) {
      updateEvent(params, { ...values }).then((res) => {
        if (res) {
          Swal.fire({
            title: 'Success!',
            text: "Record updated successfully",
            icon: 'success',
            confirmButtonText: 'OK'
          })
          setRefresh(!refresh)
          setEditableData({
            show: false,
            data: null,
          });
        }
      }).catch((err) => {
        Swal.fire({
          title: 'Error!',
          text: err.response.data.detail,
          icon: 'error',
          confirmButtonText: 'OK'
        })
      });
    } else {
      addEvent({ ...values }).then((res) => {
        if (res) {
          Swal.fire({
            title: 'Success!',
            text: "Record added successfully",
            icon: 'success',
            confirmButtonText: 'OK'
          })
          setRefresh(!refresh)
          setEditableData({
            show: false,
            data: null,
          });
        }
      }).catch((err) => {
        Swal.fire({
          title: 'Error!',
          text: err.response.data.detail,
          icon: 'error',
          confirmButtonText: 'OK'
        })
      });
    }
  };

  const listingData = useAuthStore(state=>state.listingData)
  const regionList = listingData && Object.keys(listingData)
  const dioceseList = region && listingData && listingData[region] && Object.keys(listingData[region]) || []
  const deaneryList = region && diocese && listingData && listingData[region] && listingData[region][diocese] && Object.keys(listingData[region][diocese]) || []
  const parishList = region && diocese && deanery && listingData && listingData[region] && listingData[region][diocese] && listingData[region][diocese][deanery] && Object.keys(listingData[region][diocese][deanery]) || []

  useEffect(() => {
    if (editable?.data && listingData) {
      setValues(editable?.data)
    }
  }, [editable?.data, listingData])

  return (
    <>
      {editable?.show && (
        <div className="modal-wrapper">
          <form className="addmember-form" ref={formRef} onSubmit={handleSubmit}>
          <span className="btn-close"><img src={process.env.PUBLIC_URL + "/assets/icons/charm_cross.svg"} alt="" onClick={() => setEditableData(false)} /></span>
            <h4 className="title">{id ? "Update" : "Add new"} member</h4>
            <input
              type="text"
              placeholder="Name"
              name="name"
              value={name}
              onChange={handleChange}
              required
            />
            <input
              type="email"
              placeholder="Email"
              name="email"
              value={email}
              onChange={handleChange}
              required
            />
            {!id && <input
              type="password"
              placeholder="Password"
              name="password"
              value={password}
              onChange={handleChange}
              required
            />}
            <select name="role" onChange={handleChange} value={role} required>
              <option value="">Select Role</option>
              {Object.entries(constants.role).map(([keys, roles], i) => {
                // if (roles === "parish") return null
                return <option key={i} value={roles}>{keys}</option>
              })}
            </select>
            {showSelection.region.includes(values.role) && <select name="region" onChange={handleChange} value={region} required>
              <option value="">Select Region</option>
              {regionList?.map((item, i) => {
                if (item === null || item === "null") return null
                return <option key={i} value={item}>{item}</option>
              })}
            </select>}
            {showSelection.diocese.includes(values.role) && <select name="diocese" onChange={handleChange} value={diocese} required>
              <option value="">Select Diocese</option>
              {dioceseList?.map((item, i) => {
                if (item === null || item === "null") return null
                return <option key={i} value={item}>{item}</option>
              })}
            </select>}
            {showSelection.deanery.includes(values.role) && <select name="deanery" onChange={handleChange} value={deanery} required>
              <option value="">Select Deanery</option>
              {deaneryList?.map((item, i) => {
                if (item === null || item === "null") return null
                return <option key={i} value={item}>{item}</option>
              })}
            </select>}
            {showSelection.parish.includes(values.role) && <select name="parish" onChange={handleChange} value={parish} required>
              <option value="">Select Parish</option>
              {parishList?.map((item, i) => {
                if (item === null || item === "null") return null
                return <option key={i} value={item}>{item}</option>
              })}
            </select>}
            <button type="submit">{id ? "Update" : "Save"}</button>
          </form>
        </div>
      )}
    </>
  );
};

export default AddModal;
