import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import useYouthSectionStore from "../../store/youthSectionStore";
import Swal from "sweetalert2";

const Header = () => {
  const { preView, activateYouth, inActivateYouth, refresh, setRefresh } = useYouthSectionStore(state => state)
  const { avatar, name, id, status } = preView?.data
  const navigate = useNavigate()
  const handleApprove = (id) => {
    activateYouth({ id }).then((data) => {
      if (data) { setRefresh(!refresh); navigate("/youth-section") }
    }).catch((err) => {
      Swal.fire({
        title: 'Error!',
        text: err.response.data.detail,
        icon: 'error',
        confirmButtonText: 'OK'
      })
    })

  }

  const handleCancel = (id) => {
    inActivateYouth({ id }).then((data) => {
      if (data) { setRefresh(!refresh); navigate("/youth-section") }
    }).catch((err) => {
      Swal.fire({
        title: 'Error!',
        text: err.response.data.detail,
        icon: 'error',
        confirmButtonText: 'OK'
      })
    })
  }

  return (
    <div className="member-details-header">
      <div className="profile-div">
        <img
          className="profile-img"
          src={avatar ? avatar : `${process.env.PUBLIC_URL}/assets/images/user.svg`}
          alt="img"
        />
        <h4 className="profile-heading">{name}</h4>
        <div className="profile-icons">
          {(status !== "active" && status !== "inactive") && <>
            <img
              src={`${process.env.PUBLIC_URL}/assets/icons/bluetick.svg`}
              alt="tick"
              className="pointer"
              onClick={() => handleApprove(id)}
            />
            <img
              src={`${process.env.PUBLIC_URL}/assets/icons/cross.svg`}
              alt="cross"
              className="pointer"
              onClick={() => handleCancel(id)}
            />
          </>}
          {status === "inactive" && <img
            src={`${process.env.PUBLIC_URL}/assets/icons/reactivate.svg`}
            alt="cross"
            className="pointer"
            onClick={() => handleApprove(id)}
          />}
          {status === "active" && <img
            src={`${process.env.PUBLIC_URL}/assets/icons/inactivate.svg`}
            alt="cross"
            className="pointer"
            onClick={() => handleCancel(id)}
          />}
        </div>
        <hr class="saperator" />
        {/* -----------------------MEMBER-DETAILS-ROLES-------------------------- */}
        <div className="member-details-roles">
          <NavLink to="./personal-details">
            {({ isActive }) => (
              <label
                style={{
                  background: isActive ? "#194459" : "white",
                  color: isActive ? "white" : "#5b686e",
                }}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/assets/icons/user-icon.svg`}
                  alt="cross"
                />
                <h4>Personal Details</h4>
              </label>
            )}

          </NavLink>
          <NavLink to="spiritual-details">
            {({ isActive }) => (
              <label
                style={{
                  background: isActive ? "#194459" : "white",
                  color: isActive ? "white" : "#5b686e",
                }}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/assets/icons/solid-cross.svg`}
                  alt="cross"
                />
                <h4>Spiritual Details</h4>
              </label>
            )}

          </NavLink>

          <NavLink to="family-details">
            {({ isActive }) => (
              <label
                style={{
                  background: isActive ? "#194459" : "white",
                  color: isActive ? "white" : "#5b686e",
                }}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/assets/icons/family.svg`}
                  alt="cross"
                />
                <h4>Family Details</h4>
              </label>
            )}
          </NavLink>

          <NavLink to={"education"}>
            {({ isActive }) => (
              <label
                style={{
                  background: isActive ? "#194459" : "white",
                  color: isActive ? "white" : "#5b686e",
                }}
              >
                <img
                  src={process.env.PUBLIC_URL + "/assets/icons/education.svg"}
                  alt="education"
                />
                <h4>Education</h4>
              </label>
            )}
          </NavLink>

          <NavLink to={"work-experience"}>
            {({ isActive }) => (
              <label
                style={{
                  background: isActive ? "#194459" : "white",
                  color: isActive ? "white" : "#5b686e",
                }}
              >
                <img
                  src={process.env.PUBLIC_URL + "/assets/icons/work.svg"}
                  alt=""
                />
                <h4>Work & Experience</h4>
              </label>
            )}
          </NavLink>

          <NavLink to={"licenses"}>
            {({ isActive }) => (
              <label
                style={{
                  background: isActive ? "#194459" : "white",
                  color: isActive ? "white" : "#5b686e",
                }}
              >
                <img
                  src={process.env.PUBLIC_URL + "/assets/icons/license.svg"}
                  alt=""
                />
                <h4>Licenses & Certifications</h4>
              </label>
            )}
          </NavLink>

          <NavLink to={"contact"}>
            {({ isActive }) => (
              <label
                style={{
                  background: isActive ? "#194459" : "white",
                  color: isActive ? "white" : "#5b686e",
                }}
              >
                <img
                  src={process.env.PUBLIC_URL + "/assets/icons/contact.svg"}
                  alt=""
                />
                <h4>Contact Details</h4>
              </label>
            )}
          </NavLink>
        </div>
      </div>


    </div>
  );
};

export default Header;
