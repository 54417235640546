import { useEffect } from "react";

function useHoverOut(ref, setState) {
    useEffect(() => {
        function handleHoverOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                setState(false);
            }
        }
        document.addEventListener("mouseout", handleHoverOutside);
        return () => {
            document.removeEventListener("mouseout", handleHoverOutside);
        };
    // eslint-disable-next-line 
    }, [ref]);
}


export default useHoverOut;