import React, { useRef } from 'react'
import useOutsideClickDetect from '../../../../hooks/useOutsideClick'
import "./FiltersModal.scss"

const FiltersModal = ({ showModal, setShowModal }) => {

  // Refs & States
  const filtersRef = useRef(null)
  // Hooks & Effects
  useOutsideClickDetect(filtersRef, setShowModal);

  // Handlers

  if (!showModal) return null;
  return (
    <div className="filters-wrapper" ref={filtersRef} >
      <div className="filters-header">
        <h4>Filters</h4>
        <span className='pointer' onClick={() => setShowModal(false)}>
          <img src={`${process.env.PUBLIC_URL}/assets/icons/charm_cross.svg`} alt="close" />
        </span>
      </div>
      <div className="filters-body">
        {/* <ul className="filter-sidebar">
          <li className='active'><span>Region</span></li>
          <li><span>Diocese</span></li>
          <li><span>Zone</span></li>
          <li><span>Parish</span></li>
        </ul> */}
        <ul className="filter-main">
          <li>
            <span>Region Name</span>
            <input type="checkbox" name="filter" checked={true} />
          </li>
          <li>
            <span>Region Name</span>
            <input type="checkbox" name="filter" checked={false} />
          </li>
          <li>
            <span>Region Name</span>
            <input type="checkbox" name="filter" checked={true} />
          </li>
        </ul>
      </div>
      <div className="filters-footer">
        <div className="stats">
          <span>2 selected</span>
        </div>
        <div className="button-wrapper">
          <button className="clear-all">
            Clear all
          </button>
          <button className="ok">OK</button>
        </div>
      </div>
    </div>
  )
}

export default FiltersModal