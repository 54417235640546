import React, { useCallback, useState } from "react";
import "./Notification.scss";
import Table from "./Table";
import Header from "./Header";
import NewNotification from "./Modals/NewNotificationModal/NewNotification";
import ShowNotification from "./Modals/ShowNotificationModal/ShowNotification"
import useNotificationStore from "../../store/notificationStore";

const Notification = () => {
  const { preView, editable } = useNotificationStore(store => store)
  const [query, setQuery] = useState('')

  return (
    <div className="wrapper">
      <div className="main-div">
        <Header setQuery={setQuery} />
        <div className="data-list">
          <Table query={query} />
        </div>
      </div>

      {preView?.show && <ShowNotification />}
      {editable?.show && <NewNotification />}
    </div>
  );
};

export default Notification;
