import React, { useCallback, useEffect, useState } from "react";
import "./Listing.scss";
import Header from "./Header";
import AddNew from "./Modals/AddNewModal/AddNew";
import { BASE_URL, api } from "../../utils/api";
import useFetch from "../../hooks/useFetch";
import ListNode from "./ListNode";
import useListingStore from "../../store/listingStore";

const Listing = () => {
  const initialValues = {
    region: "",
    diocese: "",
    deanery: "",
    parish: ""
  }
  const { listingTrigger, setNodeInfo, nodeInfo } = useListingStore((store) => store)
  const [selector, setSelector] = useState(initialValues)
  const [showModal, setShowModal] = useState(false);
  const headers = ["Region", "Diocese", "Zone", "Parish"];

  const handleShowModal = () => {
    setNodeInfo({
      roleType: null,
      parish: '',
      region: '',
      diocese: '',
      deanery: ''
    }); setShowModal(true)
  }
  const handleSelect = (key, str) => { setSelector({ ...selector, [key]: str }); setNodeInfo({ [key]: str }) }

  // API CALL
  const { data } = useFetch(BASE_URL + api.listing.getAll, listingTrigger);

  useEffect(() => {
    if (data) {
      setSelector({
        region: Object.keys(data)[0]
      })
      setNodeInfo({ "region": Object.keys(data)[0] })
    }
  }, [data, setNodeInfo]);

  // Inline style
  const tdStyle = {
    width: "25%",
    borderRight: " 2px solid #d9d9d9",
    paddingLeft: "10px",
  };

  // const showAll = {
  //   color: "#194459",
  //   float: "right",
  //   paddingRight: "10px",
  //   cursor: "pointer",
  //   fontWeight: 800,
  // };
  console.log("data", data)
  return (
    <div className="wrapper">
      <div className="main-div">
        <Header handleShowModal={handleShowModal} />
        <div className="data-list">
          <div className="table">
            <div className="thead">
              <div className="head">
                {headers.map((th, i) => {
                  return (
                    <div
                      className="th"
                      style={i === 0 ? { ...tdStyle, borderRight: "none" } : tdStyle}
                      key={i}
                    >
                      <span>{th}</span>
                      {/* {i > 0 && <span style={showAll}>Show all</span>} */}
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="tbody">
              <div className="head">
                <div className="regionCol">
                  {
                    data && Object.keys(data).map((name, i) => {
                      return <ListNode setShowModal={setShowModal} key={i} name={name} handleSelect={() => handleSelect("region", name)} selector={selector} roleType={"region"} />
                    })
                  }
                </div>
                <div className="regionCol">
                  {
                    data && data[selector.region] && Object.keys(data[selector.region]).map((name, i) => {
                      if (name === "null") return null
                      return <ListNode setShowModal={setShowModal} key={i} name={name} handleSelect={() => handleSelect("diocese", name)} selector={selector} roleType={"diocese"} />
                    })
                  }
                </div>
                <div className="regionCol">
                  {
                    data && data[selector.region] && data[selector.region][selector.diocese] && Object.keys(data[selector.region][selector.diocese]).map((name, i) => {
                      if (name === "null") return null
                      return <ListNode setShowModal={setShowModal} key={i} name={name} handleSelect={() => handleSelect("deanery", name)} selector={selector} roleType={"deanery"} />
                    })
                  }
                </div>
                <div className="regionCol">
                  {
                    data && data[selector.region] && data[selector.region][selector.diocese] && data[selector.region][selector.diocese][selector.deanery] && Object.entries(data[selector.region][selector.diocese][selector.deanery]).map(([name, value], i) => {
                      if (name === "null") return null
                      return <ListNode setShowModal={setShowModal} key={i} name={name} handleSelect={() => handleSelect("parish", name)} selector={selector} roleType={"parish"} value={value} />
                    })
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
        <AddNew showModal={showModal} setShowModal={setShowModal} data={data} selector={selector} />
        {/* <Diocese showModal={showModal} setShowModal={setShowModal} /> */}
      </div>
    </div>
  );
};

export default Listing;
