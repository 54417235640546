import React, { useCallback, useState } from "react";
import "./Campus.scss";
import Header from "./Header";
import Table from "./Table";
import CollegeDetails from "./Modals/CollegeDetailsModal/CollegeDetails";
import PreviewCollege from "./Modals/PreviewDetailsModal/PreviewCollege";
import useCampusStore from "../../store/campusStore";

const Campus = () => {

  const { preView, editable } = useCampusStore(state => state)

  const [showModal, setShowModal] = useState(false);

  const [showEditProfile, setShowEditProfile] = useState(false);

  const [query, setQuery] = useState("");

  const handleShowModal = () => {
    setShowModal(true);
  };

  const mamoizedShowModal = useCallback(handleShowModal, []);

  return (
    <div className="wrapper">
      <div className="campus-main-div">
        <Header handleShowModal={mamoizedShowModal} setQuery={setQuery} />
        <div className="campus-data-list">
          <Table query={query} />
        </div>
        {preView?.show && <PreviewCollege />}
        {editable?.show && <CollegeDetails />}
      </div>
    </div>
  );
};

export default Campus;
