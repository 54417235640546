import React, { useEffect, useState } from "react";
import "./RoleManagement.scss";
import { useLocation, useNavigate } from "react-router-dom";
import AddModal from "./Modals/AddModal/AddModal";
import Fallback from "./Fallback";
import Header from "./Header";
import ShowMember from "./Modals/ShowMemberModal/ShowMember";
import All from "./RMComponents/All";
import Admin from "./RMComponents/Admin";
import Region from "./RMComponents/Region";
import Diocese from "./RMComponents/Diocese";
import useRoleManagementStore from "../../store/roleManagementStore";

const RoleManagement = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { preView, editable } = useRoleManagementStore(store => store)
  const [query, setQuery] = useState('')

  useEffect(() => {
    navigate("all");
    // eslint-disable-next-line
  }, []);

  return (
    <div className="wrapper">
      <div className="main-div">
        <Header setQuery={setQuery} />
        <div className="data-list">
          {location.pathname.includes("/all") && <All query={query} />}
          {location.pathname.includes("/admin") && <Admin query={query} />}
          {location.pathname.includes("/region") && <Region query={query} />}
          {location.pathname.includes("/diocese") && <Diocese query={query} />}
          {location.pathname === "/role-management" && <Fallback />}
        </div>
      </div>
      {preView?.show && <ShowMember />}
      {editable?.show && <AddModal />}
    </div>
  );
};

export default RoleManagement;
