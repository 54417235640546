import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import useInfiniteScroll from '../../hooks/useInfiniteScroll';
import { BASE_URL, api } from '../../utils/api';
import useYouthSectionStore from '../../store/youthSectionStore';

const Youth = () => {
    const navigate = useNavigate()
    const { setPreView } = useYouthSectionStore(state => state)

    const [page, setPage] = useState(1);
    // eslint-disable-next-line 
    const [size, setSize] = useState(10);
    const { data, lastElement, loading } = useInfiniteScroll(
        BASE_URL + api.user.getAll,
        {
            page: page,
            size: size,
            is_deleted: false,
            role: "youth",
            web: true
        },
        setPage,
        "results"
    );

    const handleShare = (user) => {
        setPreView({
            show: true,
            data: user
        })
        navigate("/youth-section/preview")
    }

    return (
        <div className="youth-listing-wrapper">
            <div className="heading">
                <h4>Youth Section</h4>
                <Link to={"/youth-section"}><p className="pointer">View all</p></Link>
            </div>
            <div className="data-list">
                <table>
                    <tbody>
                        {data && data.map((item, index) => {
                            if (data.length === index + 1) {
                                return <tr key={index} ref={lastElement}>
                                    <td>{item.name}</td>
                                    <td>{item.diocese || "-"}</td>
                                    <td>{item.parish || "-"}</td>
                                    <td className="pointer" onClick={() => handleShare(item)}>
                                        <img src={`${process.env.PUBLIC_URL}/assets/icons/share.svg`} alt="" />
                                    </td>
                                </tr>
                            }
                            return <tr key={index}>
                                <td>{item.name}</td>
                                <td>{item.diocese || "-"}</td>
                                <td>{item.parish || "-"}</td>
                                <td className="pointer" onClick={() => handleShare(item)}>
                                    <img src={`${process.env.PUBLIC_URL}/assets/icons/share.svg`} alt="" />
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>
                {loading && <div className="loading"><span>Loading</span></div>}
            </div>
        </div>
    )
}

export default Youth