import React, { useState } from "react";
import "./hamburger.scss";
import Header from "./Header.jsx";
import Table from "./Table.jsx";
import useHamburgerStore from "../../store/hamburgerStore.js";
import AddHamburger from "./Modals/AddAlbumModal/AddHamburger.jsx";

function HamburgerComp() {
  const {  editable } = useHamburgerStore((state) => state);
  const [query, setQuery] = useState("");
  console.log(editable)

  return (
    <div className="wrapper">
      <div className="gallery-main-div">
        <Header setQuery={setQuery} />
        <div className="gallery-data-list">
          <Table query={query} />
        </div>
      </div>

      {editable?.show && <AddHamburger />}
    </div>
  );
}

export default HamburgerComp;
