import { create } from "zustand";
import { devtools, persist } from 'zustand/middleware'
import API from "../utils/classes/classAPI";
import { api } from "../utils/api";
import Auth from "../utils/classes/classAUTH";

const initialState = {
    auth: null,
    user: null,
    err: null,
    toggle: false,
    listingData: null
}

const authStore = (set) => ({
    ...initialState,

    loginUser: async (creds) => {
        const { data, error } = await API.postSecureRequest(api.auth.login, creds);
        if (data.result) { Auth.authenticateUser(data.token); set(() => ({ auth: data.result, user: data.user, err: error })) }
    },

    logoutUser: async (navigate) => {
        // const { data, error } = await API.postSecureRequest(api.auth.logout);
        // if (data.result) { navigate("/login") }
        set(() => (initialState))
        navigate("/login")
    },

    updateUser: async (id, body) => {
        const { data, error } = await API.putSecureRequest(api.user.updateUser + `?id=${id}`, body);
        if (data.result) { set(() => ({ user: data.result })); return data.result }
    },

    getListingData: async ()=>{
        const { data, error } = await API.getSecureRequest(api.listing.getAll);
        if (data) { set((state) => ({...state, listingData: data })) }
    },

    setToggle: async () => {
        set((state) => ({ ...state, toggle: !state.toggle }))
    }
});

const useAuthStore = create(
    devtools(
        persist(authStore, {
            name: "auth"
        })
    )
)

// const useAuthStore = create(authStore)

export default useAuthStore;