import React, { useState } from "react";
import useInfiniteScroll from "../../hooks/useInfiniteScroll";
import useSearch from "../../hooks/useSearch";
import { BASE_URL, api } from "../../utils/api";
import Swal from "sweetalert2";

const Table = ({ query }) => {

  const headers = ["Name", "Email", "Mobile no.", "Message"];

  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const { data, lastElement, loading, error } = useInfiniteScroll(
    BASE_URL + api.contact.getAll,
    {
      page: page,
      size: size
    },
    setPage,
    "results"
  );

  const searchData = useSearch(
    BASE_URL + api.contact.getAll,
    {
      page: 1,
      size: 100,
      name: query
    },
    setPage,
    "results"
  );

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text)
    Swal.fire({
      position: "bottom-end",
      text: 'Copied.',
      showConfirmButton: false,
      timer: 1000,
      width: 200,
      background: "#194459",
      color: "#FFF"
    });
  };

  return (
    <>
      <table>
        <thead>
          <tr className="head">
            {headers.map((header, index) => {
              return <th key={index}>{header}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {(query ? searchData?.data : data)?.map((user, index) => {
            if (data.length === index + 1) {
              <tr key={index} className="thisTr" ref={lastElement}>
                <td>{user.name}</td>
                <td>{user.email || "-"}  {user.email && <img
                  onClick={() => handleCopy(user.email)}
                  className="copy-icon"
                  alt="Share"
                  src={`${process.env.PUBLIC_URL}/assets/icons/copy.svg`}
                ></img>}</td>
                <td>{user.mobile || "-"}  {user.mobile && <img
                  onClick={() => handleCopy(user.mobile)}
                  className="copy-icon"
                  alt="Share"
                  src={`${process.env.PUBLIC_URL}/assets/icons/copy.svg`}
                ></img>}</td>
                <td>{user.message || "-"}</td>
              </tr>
            }
            return (
              <tr key={index} className="thisTr">
                <td>{user.name}</td>
                <td>{user.email || "-"}  {user.email && <img
                  className="copy-icon"
                  onClick={() => handleCopy(user.email)}
                  alt="Share"
                  src={`${process.env.PUBLIC_URL}/assets/icons/copy.svg`}
                ></img>}</td>
                <td>{user.mobile || "-"}  {user.mobile && <img
                  className="copy-icon"
                  onClick={() => handleCopy(user.mobile)}
                  alt="Share"
                  src={`${process.env.PUBLIC_URL}/assets/icons/copy.svg`}
                ></img>}</td>
                <td>{user.message || "-"}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {loading && <div className="loading"><span>Loading</span></div>}
      {error && <div className="error"><span>Unable to load data</span></div>}
    </>

  );
};

export default Table;
