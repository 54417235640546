import React, { useState } from "react";
import "./PersonalDetails.scss";
import useYouthSectionStore from "../../../store/youthSectionStore";
import moment from "moment";

const PersonalDetails = () => {
  const [color, setColor] = useState(false);
  const { setPreView, preView } = useYouthSectionStore(state => state)
  const { name, email, dob, gender, bloodGroup } = preView?.data
  return (
    <div className="personal-details-wrapper">
      <div className="names">
        <span>
          <h6>Full Name</h6>
          <input type="text" placeholder="Full Name" value={name} readOnly />
        </span>
      </div>

      <div className="username">
        <h6>Email</h6>
        <input type="text" placeholder="Email" value={email} readOnly />
      </div>

      <div className="date-of-birth">
        <h6>Date of Birth</h6>
        <input type="text" placeholder="DOB" value={moment(dob).format("DD/MM/YYYY")} readOnly />
      </div>

      <span>
        <h6>Gender</h6>
        <div className="gender-labels">
          <label
            // htmlFor="1"
            // onClick={() => setColor(1)}
            style={{
              background: gender === "m" ? "#194459" : "white",
              color: gender === "m" ? "white" : "#5b686e",
            }}
          >
            <input className="audience-input" type="radio" />
            <span>Male</span>
          </label>

          <label
            // htmlFor="2"
            // onClick={() => setColor(2)}
            style={{
              background: gender === "f" ? "#194459" : "white",
              color: gender === "f" ? "white" : "#5b686e",
            }}
          >
            <input className="audience-input" type="radio" />
            <span>Female</span>
          </label>
        </div>
      </span>

      <div className="blood-group">
        <h6>Blood Group</h6>
        <input type="text" placeholder="Blood Group" value={bloodGroup} readOnly />
      </div>
    </div>
  );
};

export default PersonalDetails;
