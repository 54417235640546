import React from "react";
import "./SpiritualDetails.scss";
import useYouthSectionStore from "../../../store/youthSectionStore";
import moment from "moment";

const SpiritualDetails = () => {
  const { setPreView, preView } = useYouthSectionStore(state => state)
  const { rite, communionDate, confirmationDate } = preView?.data
  return (
    <div className="spiritual-details-wrapper">

      <div className="Rite">
        <h6>Rite</h6>
        <input type="text" placeholder="Name" value={rite} readOnly />
      </div>

      <div className="names">
        <div>
          <div className="names-heading">
            <img
              className="solid-cross"
              src={`${process.env.PUBLIC_URL}/assets/icons/solid-cross.svg`}
              alt="back"
            />
            <h6>
              Sacrament of communion
            </h6>
          </div>
          <input type="text" placeholder="month yyyy" value={moment(communionDate).format("MMM YYYY")} readOnly />
        </div>
        <div>

          <div className="names-heading">
            <img
              className="solid-cross"
              src={`${process.env.PUBLIC_URL}/assets/icons/solid-cross.svg`}
              alt="back"
            />
            <h6>
              Sacrament of confirmation
            </h6>
          </div>
          <input type="text" placeholder="Date" value={moment(confirmationDate).format("MMM YYYY")} readOnly />
        </div>
      </div>
    </div>
  );
};

export default SpiritualDetails;
