import React from "react";
import "./ContactDetails.scss";
import useYouthSectionStore from "../../../store/youthSectionStore";

const ContactDetails = () => {
  const { setPreView, preView } = useYouthSectionStore(state => state)
  const { email, mobile, alternameMobile, alternateEmail, whatsAppMobile, currentAddress, permanentAddress } = preView?.data
  return (
    <div className="contact-details-wrapper">
      <div className="email">
        <div>
          <div className="email-heading">
            <img
              className="email"
              src={`${process.env.PUBLIC_URL}/assets/icons/email.svg`}
              alt="back"
            />
            <h6>Email ID</h6>
          </div>
          <input type="text" placeholder="Email ID" value={email} readOnly />
        </div>

        <div>
          <div className="email-heading">
            <img
              className="email"
              src={`${process.env.PUBLIC_URL}/assets/icons/email.svg`}
              alt="back"
            />
            <h6>Alternate Email ID</h6>
          </div>
          <input type="text" placeholder="Alternate Email ID" value={alternateEmail} readOnly />
        </div>
      </div>
      {/* ------------------------Mobile no---------------------- */}
      <div className="mobile-no">
        <div>
          <div className="mobile-no-heading">
            <img
              className="mobile-no"
              src={`${process.env.PUBLIC_URL}/assets/icons/mobile.svg`}
              alt="back"
            />
            <h6>mobile-no</h6>
          </div>
          <input type="text" placeholder="mobile-no" value={mobile} readOnly />
        </div>

        <div>
          <div className="whatsapp-heading">
            <img
              className="whatsapp"
              src={`${process.env.PUBLIC_URL}/assets/icons/whatsapp.svg`}
              alt="back"
            />
            <h6>Whatsapp No.</h6>
          </div>
          <input type="text" placeholder="Whatsapp No." value={whatsAppMobile} readOnly />
        </div>
      </div>

      {/* ------------------------alternate-mobile-number---------------------- */}

      <div className="alternate-mobile-no">
        <div>
          <div className="alternate-mobile-no-heading">
            <img
              className="alternate-mobile-no"
              src={`${process.env.PUBLIC_URL}/assets/icons/mobile.svg`}
              alt="back"
            />
            <h6>Alternate Mobile No.</h6>
          </div>
          <input type="text" placeholder="Alternate Mobile No." value={alternameMobile} readOnly />
        </div>

        {/* <div className="date">
          <h6>End Date</h6>
          <input type="text" placeholder="Date" />
        </div> */}
      </div>

      {/* ------------------------ address---------------------- */}

      {<div className="address">
        <div>
          <div className="address-heading">
            <img
              className="address-icon"
              src={`${process.env.PUBLIC_URL}/assets/icons/location.svg`}
              alt="back"
            />
            <h6>Current Address</h6>
          </div>
          <input className="address-input" type="text" placeholder="Current Address" value={currentAddress?.address} readOnly />
        </div>
        <div className="postcode">
          <input type="text" placeholder="location" value={currentAddress?.location} readOnly />
          <input type="text" placeholder="postcode" value={currentAddress?.pincode} readOnly />
        </div>
      </div>}

      {/* ------------------------permanent address---------------------- */}

      {<div className="address">
        <div>
          <div className="address-heading">
            <img
              className="address-icon"
              src={`${process.env.PUBLIC_URL}/assets/icons/location.svg`}
              alt="back"
            />
            <h6>Permanent Address</h6>
          </div>
          <input className="address-input" type="text" placeholder="Current Address" value={permanentAddress?.address} readOnly />
        </div>
        <div className="postcode">
          <input type="text" placeholder="location" value={permanentAddress?.location} readOnly />
          <input type="text" placeholder="postcode" value={permanentAddress?.pincode} readOnly />
        </div>
      </div>}
    </div>
  );
};

export default ContactDetails;
