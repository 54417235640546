import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "./Header";
import NewsUpdate from "./Modals/NewsUpdateModal/NewsUpdate";
import "./Announcement.scss";
import Shownews from "./Modals/ShowNewsModal/ShowNews";
import All from "./NAComponents/All";
import News from "./NAComponents/News";
import Announce from "./NAComponents/Announce";
import useNewsStore from "../../store/newsStore";
import Fallback from "./Fallback";

const Announcement = () => {
  const { preView, editable } = useNewsStore(store => store)
  const navigate = useNavigate();
  const location = useLocation();

  const [query, setQuery] = useState('')

  useEffect(() => {
    navigate("all");
  }, []);

  return (
    <div className="wrapper">
      <div className="main-div">
        <Header setQuery={setQuery} />
        <div className="data-list">
          {location.pathname.includes("/all") && <All query={query} />}
          {location.pathname.includes("/news") && <News query={query} />}
          {location.pathname === "/announcement/announcement" && <Announce query={query} />}
          {location.pathname === "/announcement" && <Fallback />}
        </div>
        {preView?.show && <Shownews />}
        {editable?.show && <NewsUpdate />}
      </div>
    </div>
  );
};

export default Announcement;
