import { create } from "zustand";
import { api } from "../utils/api";
import API from "../utils/classes/classAPI";

const initialState = {
    preView: {
        show: false,
        data: null
    },
    editable: {
        show: false,
        data: null
    },
    refresh: false
}

const galleryStore = (set) => ({
    ...initialState,

    setPreView: (node) => {
        set((state) => ({ ...state, preView: node }))
    },

    setEditableData: (node) => {
        set((state) => ({ ...state, editable: node }))
    },

    setRefresh: (bool) => {
        set((state) => ({ ...state, refresh: bool }))
    },

    addEvent: async (body) => {
        const { data, error } = await API.postSecureRequest(api.gallery.post, body);
        return { data, error }
    },

    updateEvent: async (params, body) => {
        const [key, value] = Object.entries(params)[0]
        const { data, error } = await API.putSecureRequest(`${api.gallery.put}?${key}=${value}`, body);
        return { data, error }
    },

    deleteEvent: async (params) => {
        const [key, value] = Object.entries(params)[0]
        const { data, error } = await API.deleteSecureRequest(`${api.gallery.delete}?${key}=${value}`);
        return { data, error }
    },
});

const useGalleryStore = create(galleryStore)

export default useGalleryStore;