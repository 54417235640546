import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import useAuthStore from "../../store/authStore";
import { DebounceInput } from "react-debounce-input";
import useNewsStore from "../../store/newsStore";

const Header = ({ setQuery }) => {

  const { setEditableData } = useNewsStore(store => store)
  const handleSearch = (e) => setQuery(e.target.value)
  const handleAdd = () => { setEditableData({ show: true, data: null }); }

  return (
    <div className="head-wrapper">
      <div className="announcement-roles">
        <NavLink to="all">
          <h4>All</h4>
        </NavLink>
        <NavLink to="news">
          <h4>News</h4>
        </NavLink>
        <NavLink to="announcement">
          <h4>Announcement</h4>
        </NavLink>
      </div>
      <div className="search-wrapper">
        <div className="rectangle-group">
          <img
            className="search-icon"
            src={process.env.PUBLIC_URL + "/assets/icons/search.svg"}
            alt=""
          />
          <DebounceInput
            minLength={2}
            debounceTimeout={500}
            className="text-area"
            placeholder={"Search"}
            onChange={handleSearch}
          />
        </div>

        <button className="add-btn pointer" onClick={handleAdd}>
          +Add new
        </button>
      </div>
    </div>
  );
};

export default Header;
